<template>
    <v-container>
        <v-dialog width="800px" v-model="imageViewer">
            <v-card>
                <v-img :src="imageViewerUrl"></v-img>
            </v-card>

        </v-dialog>
        <v-dialog v-model="eventDailog" max-width="1000px" persistent>
            <v-card>
                <v-card-title>{{dialogTitle}}战令活动</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row dense>
                            <v-col cols="3">
                                <v-text-field v-model="itemEdit.passName" label="战令名称"></v-text-field>
                            </v-col>
                             <v-col cols="3">
                                <v-text-field v-model="itemEdit.passId" label="战令id"></v-text-field>
                            </v-col>
                        </v-row>                    
                       <v-row dense>
                           <v-col cols="5">
                               <v-menu
                                   v-model="startMenu"
                                   :close-on-content-click="false"
                                   :nudge-right="40"
                                   transition="scale-transition"
                                   offset-y
                                   min-width="auto"
                               >
                               <template v-slot:activator="{ on, attrs }">
                                   <v-text-field
                                       v-model="itemEdit.startTime"
                                       label="开始日期"
                                       prepend-icon="mdi-calendar"
                                       readonly
                                       v-bind="attrs"
                                       v-on="on"
                                   >                                    
                                   </v-text-field>
                               </template>
                               <v-date-picker
                                   v-model="itemEdit.startTime"
                                   @input="startMenu = false"
                               >                                     
                               </v-date-picker>
                               
                               </v-menu>
                           </v-col>
                           <v-spacer></v-spacer>
                           <v-col cols="5">
                               <v-menu
                                   v-model="endMenu"
                                   :close-on-content-click="false"
                                   :nudge-right="40"
                                   transition="scale-transition"
                                   offset-y
                                   min-width="auto"
                               >
                               <template v-slot:activator="{ on, attrs }">
                                   <v-text-field
                                       v-model="itemEdit.endTime"
                                       label="结束日期"
                                       prepend-icon="mdi-calendar"
                                       readonly
                                       v-bind="attrs"
                                       v-on="on"
                                   >                                    
                                   </v-text-field>
                               </template>
                               <v-date-picker
                                   v-model="itemEdit.endTime"
                                   @input="endMenu = false"
                               >                                     
                               </v-date-picker>
                               
                               </v-menu>
                           </v-col>
                       </v-row>
                       <v-row dense>
                            <v-col cols="3">
                                <v-file-input
                                    label="入口图"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    @change="(files) => fileuploadChange(files, 'entryImg')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                            <v-col cols="3">
                                <v-file-input
                                    label="背景图"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    @change="(files) => fileuploadChange(files, 'bgImg')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                            <v-col cols="3">
                                <v-file-input
                                    label="背景图遮罩"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    @change="(files) => fileuploadChange(files, 'bgMaskImg')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                            <v-col cols="3">
                                <v-file-input
                                    label="分割线"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    @change="(files) => fileuploadChange(files, 'splitLineImg')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                            <v-col cols="3">
                                <v-file-input
                                    label="奖品背景"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    @change="(files) => fileuploadChange(files, 'rewardBgImg')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                           
                       </v-row>
                       <v-divider></v-divider>
                       <v-row dense>
                            <v-textarea
                                    v-model="freeRewardJsonStr"
                                    label="免费奖励"
                                    outlined
                                    rows="1"
                                    auto-grow
                            ></v-textarea>                              
                       </v-row>
                       <v-row dense>
                            <v-textarea
                                    v-model="premiumRewardJsonStr"
                                    label="付费奖励"
                                    outlined
                                    rows="1"
                                    auto-grow
                            ></v-textarea>                              
                       </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" @click="DailogCancel" text>取消</v-btn>
                    <v-btn color="blue darken-1" @click="DailogSave" text>确定</v-btn>
                </v-card-actions>
            </v-card>
   
        </v-dialog>
   
        <v-card loading="uploading">
            <v-toolbar flat>
                <v-toolbar-title>战令配置</v-toolbar-title>
                <v-divider vertical class="mx-2"></v-divider>
                <v-spacer></v-spacer>
                <v-btn outlined color="grey darken-2" @click="addEventDailog">新增</v-btn>
            </v-toolbar>
            <v-data-table
               :headers="headers"
               :items="items"
               :options.sync="options"
               :server-items-length="serverLength"
               :loading="loading"
               loading-text="加载中..."
               :search="search"
               class="elevation-2"
            >
            <template v-slot:item.entryImg="{ item }">
                <v-img @click="viewImage(item.entryImg)" class="rounded-lg" max-width="60" max-height="38" :src="item.entryImg+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.bgImg="{ item }">
                <v-img @click="viewImage(item.bgImg)" class="rounded-lg" max-width="60" max-height="38" :src="item.bgImg+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.splitLineImg="{ item }">
                <v-img @click="viewImage(item.splitLineImg)" class="rounded-lg" max-width="60" max-height="38" :src="item.splitLineImg+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.rewardBgImg="{ item }">
                <v-img @click="viewImage(item.rewardBgImg)" class="rounded-lg" max-width="60" max-height="38" :src="item.rewardBgImg+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>
            <template v-slot:item.bgMaskImg="{ item }">
                <v-img @click="viewImage(item.bgMaskImg)" class="rounded-lg" max-width="60" max-height="38" :src="item.bgMaskImg+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>

            <template v-slot:item.modifyTime="{item}">
                <v-chip>{{getTimeStrFromTimestamp(item.modifyTime)}}</v-chip>
            </template>
   
            <template v-slot:item.actions="{item}">
                 <v-icon
                   small
                   class="mr-2"
                   @click="editItem(item)"
              >
                   mdi-pencil
              </v-icon>
              <v-icon
                   small
                   @click="deleteItem(item)"
              >
                   mdi-delete
              </v-icon>
            </template>
   
            </v-data-table>
        </v-card>
    </v-container>
   </template>
   
   <script>
   import axios from 'axios'
   import {bus} from '@/main'
   
   export default ({
       name : "PoolPassConfig",
       components: {
           
       },
       data: function(){
           return {
               headers: [
                   {
                       text: "战令Id",
                       align: "center",
                       sortable: false,
                       value: "passId",
                       width: "10%",
                   },
                   {
                       text: "战令名称",
                       align: "center",
                       sortable: false,
                       value: "passName",
                       width: "10%",
                   },
                   {
                       text: "战令入口图",
                       align: "center",
                       sortable: false,
                       value: "entryImg",
                       width: "5%",
                   },
                   {
                       text: "背景图",
                       align: "center",
                       sortable: false,
                       value: "bgImg",
                       width: "5%",
                   },
                   {
                       text: "背景图遮罩",
                       align: "center",
                       sortable: false,
                       value: "bgMaskImg",
                       width: "5%",
                   },
                   {
                       text: "分割线图",
                       align: "center",
                       sortable: false,
                       value: "splitLineImg",
                       width: "5%",
                   },
                   {
                       text: "奖励背景图",
                       align: "center",
                       sortable: false,
                       value: "rewardBgImg",
                       width: "5%",
                   },                
                   {
                       text: "开始时间",
                       align: "center",
                       sortable: false,
                       value: "startTime",
                       width: "10%",
                   },
                   {
                       text: "结束时间",
                       align: "center",
                       sortable: false,
                       value: "endTime",
                       width: "10%",
                   },
                   {
                       text: "修改日期",
                       align: "center",
                       sortable: false,
                       value: "modifyTime",
                       width: "10%",
                   },
                   {
                       text: "操作",
                       align: "center",
                       sortable: false,
                       value: "actions",
                       width: "10%",
   
                   },
               ],
               loading:false,
               tabs: null,
               tabdata: null,
               eventDailog: false,
               uploading:false,
               editLang: '',
               startMenu:false,
               dialogDelete: false,
               imageViewer: false,
               imageViewerUrl: "",
               search: "",
               options:{},
               items:[],
               endMenu:false,
               serverLength:0,
               editLocalName: '',
               isModifying: false,
               freeRewardJsonStr: '',
               premiumRewardJsonStr: '',
               itemEdit:{
                   _id: '',
                   passId: '',
                   startTime: '',
                   endTime: '',
                   passName: '',
                   entryImg: '',
                   bgImg: '',
                   splitLineImg: '',
                   rewardBgImg: '',
                   bgMaskImg: '',
                   localName: {},
                   freeReward: [],
                   premiumReward: [],

               }
           }
       },
       computed: {
           //  isFreeLable:function(){
           //     return this.itemEdit.isFree ? "免费": "收费";
           // },
           dialogTitle: function(){
               return this.isModifying ? "修改":"新建";
           },
           // dialogTitle: function(){
           //     return this.isModifying?"修改Picture":"新建Picture";
           // },
       },

    

       watch: {
           options:{
               handler(){
                   this.reloadData();
               },
               deep: true,
           },
           freeRewardJsonStr: function(){
               try{
                   this.itemEdit.freeReward = JSON.parse(this.freeRewardJsonStr);
               }catch(e){
                   bus.$emit("showSnackMessage", "json格式错误 ", true)
                   this.freeRewardJsonStr = JSON.stringify(this.itemEdit.freeReward);
                   console.log(e);
               }
           },
            premiumRewardJsonStr: function(){
            try{
                    this.itemEdit.premiumReward = JSON.parse(this.premiumRewardJsonStr);
            }catch(e){
                    bus.$emit("showSnackMessage", "json格式错误 ", true)
                    this.premiumRewardJsonStr = JSON.stringify(this.itemEdit.premiumReward);
                    console.log(e);
            }
            },
   
       },
   
       methods: {
           // addEditLevelToEditPack: function(){
           //     this.packItemEdit.levels.push({...this.levelEdit})
           // },
           viewImage(url){
               this.imageViewerUrl = url;
               this.imageViewer = true;
           },
           clearitemEdit: function(){
               this.isModifying = false;
               this.itemEdit = {
                   _id: '',
                   passId: '',
                   startTime: '',
                   endTime: '',
                   passName: '',
                   entryImg: '',
                   bgImg: '',
                   splitLineImg: '',
                   rewardBgImg: '',
                   bgMaskImg: '',
                   localName: {},
                   freeReward: [],
                   premiumReward: [],
               };
               this.refreshJsonStr();
           },
           refreshJsonStr: function(){
                this.freeRewardJsonStr = JSON.stringify(this.itemEdit.freeReward, null, 2);
                this.premiumRewardJsonStr = JSON.stringify(this.itemEdit.premiumReward, null, 2);
            },
           modEventMeta: function(eventItem){
               this.itemEdit = Object.assign({}, eventItem);
               this.isModifying =true;
               this.eventDailog = true;
           },
           getIsFreeColor: function(isFree){
               return isFree?"green":"orange";
           },
           deleteLocalName: function(name){
               console.log(name);
               this.$delete(this.itemEdit.localName, name);
           },
           getTimeStrFromTimestamp(timestamp){
               var s = new Date(timestamp * 1000)
               return s.toLocaleString("zh-CN")
           },
           getIsFreeContent: function(isFree){
               return isFree?"免费":"付费";
           },
           addEventDailog: function(){
               this.clearitemEdit();
               this.eventDailog = true;
           },
   
           modCollection: function(col){
               this.itemEdit = col;
               this.refreshJsonStr();
               this.isModifying = true;
               this.eventDailog = true;
               console.log(col);
           },
   
           delCollection: function(col){
               this.itemEdit = col;
               this.refreshJsonStr();
               this.isModifying = true;
               this.delCol();
               console.log(col);
           },
           fileuploadChange: function(files, point){
               console.log(files)
               console.log(point)
               var formData = new FormData();
               formData.append("image", files);
               formData.append("product", "poolpass")
               var vm = this;
               axios.post("/admin/api/uploadFile", formData, {
                   headers: {
                       'Content-Type': 'multipart/form-data'
                   }
               }).then(function(response){
                   if(response.data.status == 0){
                       vm.itemEdit[point] = response.data.data.url;
                       //vm.itemEdit.hash = response.data.data.hash;
                   }else{
                       bus.$emit("showSnackMessage", "图片上传失败 " + response.data.msg, true)
                   }
                 
               }).catch(function(){
                   bus.$emit("showSnackMessage", "图片上传失败 ", true)
                   console.log("upload failed");
   
               })
           },
   
           editItem: function(item){
               var index = this.items.indexOf(item);
               if (index !== -1){
                   this.isModifying = true;
                   this.itemEdit = Object.assign({}, item);
                   this.refreshJsonStr();
                   this.eventDailog = true;
               }else{
                   this.isModifying = false;
               }
              
           },
   
           deleteItem: function(item){
               console.log("delete item clicked");
               this.itemEdit = Object.assign({}, item);
               this.dialogDelete = true;
               var vm = this;
               this.$confirm("确定要删除?").then(res => {
                   if(res === true){
                       var form = new FormData();
                       form.append("_id", this.itemEdit._id);
                       axios.post("/admin/api/poolclash/delPoolPass", form, {
                           headers: {
                                   'Content-Type': 'application/x-www-form-urlencoded'
                           }
                       }).then(function(response){
                           if(response.data.status == 0){
                                bus.$emit("删除成功");
                                vm.clearitemEdit();
                                vm.isModifying = false;
                                vm.reloadData();
                           }else{
                               bus.$emit("showSnackMessage", "删除错误: " + response.data.msg, true);                      
                           }
                       }).catch(function(err){
                           bus.$emit("showSnackMessage", "错误发生: " + err, true);
                       })
                   }
   
               })
           },
   
           realAddEvent: function(eventItem){
               this.uploading = true;
               var vm = this;
               //eventItem.levelCnt = eventItem.levels.length;
               var formData = new FormData();
               formData.append("data", JSON.stringify(eventItem));
               console.log(formData);
            //    for ( var key in eventItem ) {
            //        var item = eventItem[key];
            //        if (key == "packages"){
            //            formData.append(key, JSON.stringify(item));
            //            continue;
            //        }
   
                   
            //        // if (Array.isArray(item)){
            //        //     for(var index in item){
            //        //         formData.append(key, item[index])
            //        //     }
            //        // }else 
                   
            //        if (typeof item === "object" && item != null){
            //            formData.append(key, JSON.stringify(item))
            //        }else{
            //            formData.append(key, item)
            //        }
                   
            //    }
               axios.post('/admin/api/poolclash/addPoolPass', formData, {
                   headers: {
                           'Content-Type': 'application/x-www-form-urlencoded'
                   }
               }).then(function(response){              
                   console.log(response);
                   vm.clearitemEdit();
                   if(response.data.status == 0){
                       vm.isModifying = false;
                       vm.eventDailog = false;
                       vm.uploading = false;
                       bus.$emit("showSnackMessage", "新增成功")
                       vm.reloadData();
                   }else{
                       bus.$emit("showSnackMessage", "新增/修改失败 " + response.data.status + " " + response.data.msg, true);
                       vm.uploading = false;
                   }
                   
               }).catch(function(error){
                   console.log(error);
                   vm.uploading = false;
                   bus.$emit("showSnackMessage", "新增失败" + error, true)
                   vm.reloadData();
               });
           },
   
           
           reloadData: function(){
               console.log("will reload data");
               var vm = this;
               const { page, itemsPerPage } = this.options
               axios.get("/admin/api/poolclash/listPoolPass?page="+(page-1)+'&step='+itemsPerPage).then(function(response){
                   if(response.data.status == 0){
                       console.log(response);
                       vm.items = response.data.data.list;
                       vm.loading=false;        
                       vm.serverLength = response.data.data.totalCnt;
                   }else{
                        console.log(response.data.status);
                       bus.$emit("showSnackMessage", "刷新event数据失败" + response.data.msg, true);
                   }
               }).catch(function(err){
                   bus.$emit("showSnackMessage", "刷新collection数据失败，出现异常 ：" +err);
               })
           },
           DailogSave: function(){
               this.realAddEvent(this.itemEdit);           
           },
           DailogCancel: function(){
               this.eventDailog = false;
               this.clearitemEdit();
           },
           isFreeChange: function(){
               this.itemEdit.coins = 0;
               this.itemEdit.price = 0;
               this.itemEdit.IAPId = "";
               
           }
   
       },
   
       mounted: function(){
           this.reloadData();
       },
   })
   </script>
   
   <style >
   .v-tab {
   text-transform: none !important;
   }
   </style>   