var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-dialog',{attrs:{"max-width":"1000px","persistent":""},model:{value:(_vm.itemDailog),callback:function ($$v) {_vm.itemDailog=$$v},expression:"itemDailog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.dialogTitle)+"巧克配置")]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"巧克ID"},model:{value:(_vm.itemEdit.chalkId),callback:function ($$v) {_vm.$set(_vm.itemEdit, "chalkId", $$v)},expression:"itemEdit.chalkId"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-file-input',{attrs:{"label":"静态展示图","accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-file-image"},on:{"change":function (files) { return _vm.fileuploadChange(files, 'img'); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}])})],1)],1),_c('v-divider'),_c('v-row',{attrs:{"dense":""}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary"}},[_vm._v(" 普通 BUNDLE ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-file-input',{attrs:{"label":"unity bundle android","accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-android"},on:{"change":function (files) { return _vm.fileuploadChange(files, 'bundle_and'); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-file-input',{attrs:{"label":"unity bundle ios","accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-apple"},on:{"change":function (files) { return _vm.fileuploadChange(files, 'bundle_ios'); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}])})],1)],1),_c('v-divider'),_c('v-row',{attrs:{"dense":""}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red"}},[_vm._v(" IDLE BUNDLE ")]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-file-input',{attrs:{"label":"idle unity bundle android","accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-android"},on:{"change":function (files) { return _vm.fileuploadChange(files, 'idle_bundle_and'); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-file-input',{attrs:{"label":"idle unity bundle ios","accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-apple"},on:{"change":function (files) { return _vm.fileuploadChange(files, 'idle_bundle_ios'); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-textarea',{attrs:{"label":"属性","outlined":"","rows":"1","auto-grow":""},model:{value:(_vm.propertyJsonStr),callback:function ($$v) {_vm.propertyJsonStr=$$v},expression:"propertyJsonStr"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.DailogCancel}},[_vm._v("取消")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.DailogSave}},[_vm._v("确定")])],1)],1)],1),_c('v-card',{attrs:{"loading":"uploading"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("巧克配置")]),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.addItemDailog}},[_vm._v("新增")])],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.serverLength,"loading":_vm.loading,"loading-text":"加载中..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.img",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"rounded-lg",attrs:{"max-width":"60","max-height":"38","src":item.img+'?x-oss-process=style/w_200'},on:{"click":function($event){return _vm.viewImage(item.img)}}})]}},{key:"item.modifyTime",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(_vm.getTimeStrFromTimestamp(item.modifyTime)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }