<template>
    <v-container>
        <v-dialog width="800px" v-model="magnifyImgDialog">
            <v-card>
                <v-img :src="magnifyImgUrl"></v-img>
            </v-card>

        </v-dialog>
        <v-data-table
            :headers = "headers"
            :items = "items"
            :options.sync="options"
            :server-items-length="serverLength"
            :loading="loading"
            loading-text="加载中..."
            class="elevation-2"
        >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>关卡</v-toolbar-title>
                <v-divider vertical inset class="mx-4"></v-divider>
                <v-spacer></v-spacer>
                <v-col cols="2">
                    <v-checkbox v-model="filterSpecial" label="特殊关卡"></v-checkbox>
                </v-col>
                <v-dialog v-model="editDialog" max-width="500px">
                    <template v-slot:activator="{on, attrs}">
                        <v-btn
                            color="primary"
                            dark
                            outlined
                            v-bind="attrs"
                            v-on="on"
                        >
                            新建
                        </v-btn>
                    </template>
                    <v-card :loading="editedItem.levelIdLoading">
                        <v-img class="mb-1" v-if="editedItem.url != ''" height="200" :src="editedItem.url"></v-img>
                        <v-card-title v-if="editedItem.url == ''">{{ dialogTitle }}</v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row dense>
                                     <v-col cols="12">
                                        <v-checkbox v-model="isSpecialEdit" label="特殊关卡"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-file-input
                                            filled
                                            prepend-icon="mdi-android"
                                            label="安卓 asset"
                                            v-model="editedItem.androidAssetFile"
                                        >
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-file-input
                                            filled
                                            prepend-icon="mdi-apple"
                                            label="iOS asset"
                                            v-model="editedItem.iosAssetFile"
                                        >

                                        </v-file-input>
                                    </v-col>
                                    <!-- <v-col cols="12">
                                        <v-file-input
                                            label="上传主图"
                                            v-model="editedItem.mainImg"
                                            filled
                                            accept="image/png, image/jpeg, image/bmp"
                                            prepend-icon="mdi-camera"
                                        >
                                            <template v-slot:selection="{ text }">
                                                <v-chip
                                                    small
                                                    label
                                                    color="primary"
                                                >{{ text }}</v-chip>
                                            </template>
                                        </v-file-input>
                                    </v-col>
                                   
                                    <v-col cols="12">
                                         <v-file-input
                                            label="上传副图"
                                            v-model="editedItem.subImg"
                                            filled
                                            accept="image/png, image/jpeg, image/bmp"
                                            prepend-icon="mdi-camera"
                                        >
                                            <template v-slot:selection="{ text }">
                                                <v-chip
                                                    small
                                                    label
                                                    color="primary"
                                                >{{ text }}</v-chip>
                                            </template>
                                        </v-file-input>
                                    </v-col> -->
                                    <v-col cols="12">
                                        <v-text-field
                                            label="url"
                                            v-model="editedItem.url"
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            label="关卡id"
                                            :disabled = "editedItem.levelIdLoading"
                                            v-model="editedItem.levelId"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="cancelEdit"
                            >
                                取消
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="saveEdit"
                            >保存</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>

        <template v-slot:item.androidAsset="{item}">
            <v-icon @click="downloadItem({url:item.androidAsset, label: item.levelId + '_and.asset'} )">mdi-file</v-icon>
            <!-- <v-img class="rounded-lg" max-width="60" max-height="38" :src="item.url+'?x-oss-process=style/w_200'" @click="magnifyImage(item.url)"></v-img> -->
        </template>
            <template v-slot:item.iosAsset="{item}">
            <v-icon @click="downloadItem({url:item.iosAsset, label: item.levelId + '_ios.asset'} )">mdi-file</v-icon>
            <!-- <v-img class="rounded-lg" max-width="60" max-height="38" :src="item.url+'?x-oss-process=style/w_200'" @click="magnifyImage(item.url)"></v-img> -->
        </template>


        <!-- <template v-slot:item.subUrl="{item}">
            <v-img class="rounded-log" max-width="60" max-height="38" :src="item.subUrl+'?x-oss-process=style/w_200'" @click="magnifyImage(item.subUrl)"></v-img>
        </template> -->

        <template v-slot:item.modifyTime="{ item }">
           <v-chip>{{getTimeStrFromTimestamp(item.modifyTime)}}</v-chip>
       </template>

        <template v-slot:item.actions="{item}">
             <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
           >
                mdi-pencil
           </v-icon>
           <v-icon
                small
                @click="deleteItem(item)"
           >
                mdi-delete
           </v-icon>
        </template>

        </v-data-table>
    </v-container>
</template>
<script>
import axios from 'axios'
import {bus} from '@/main'
export default ({
   name: "FdLevelConfig",
   watch:{
        isSpecialEdit:function(val){
            console.log("is special edit :" + val);
            this.getNextLevelId()
        },
      filterSpecial: function(val){
            console.log("filter special " + val);
            this.reloadData();
        },
      editDialog: function(val){
          if (val==true && this.isEditing == false){
              this.getNextLevelId();
          }
      },
       options:{
            handler(){
                this.reloadData();
            },
            deep: true,
        },

   },
   data: function(){
       return {
           headers: [
               {
                    text: "Id",
                    align: "center",
                    sortable: false,
                    value: "id",
                    width: "10%",

                },
                {
                    text: "LevelId",
                    align: "center",
                    sortable: true,
                    value: "levelId",
                    width: "10%",
                },              
                {
                    text: "安卓Asset",
                    align: "center",
                    value: "androidAsset",
                    width: "10%",
                },
                {
                    text: "iOS Asset",
                    align: "center",
                    value: "iosAsset",
                    width: "10%",
                },
                // {
                //     text: "副图",
                //     align: "center",
                //     value: "subUrl",
                //     width: "10%",
                // },
                {
                    text: "地域",
                    align: "center",
                    value: "regions",
                    width: "20%",
                },
            
                {
                    text: "修改时间",
                    align: "center",
                    value: "modifyTime",
                    width: "10%",
                },
                {
                    text: "操作",
                    align: "center",
                    value: "actions",
                    width: "10%",
                }
           ],
           items: [],
           dialogTitle:"新建Level",
           loading: true,
           editDialog: false,
           isEditing:false,
           filterSpecial: false,
           options: {},
           serverLength:0,
           isSpecialEdit:false,
           magnifyImgUrl: '',
           magnifyImgDialog:false,
           editedItem:{
               levelIdLoading: true,
               isSpecial:false,
               url: '',
               androidAssetFile:null,
               iosAssetFile:null,          
               levelId: '',
               FileId: '',
               LevelIdInt:0,
           }

       }
   },
   methods:{
        downloadItem: function({ url, label }) {
            console.log(url)
            console.log(label)
            axios.get(url, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = label
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        },

        getNextLevelId: function(){
            var vm = this;
            axios.get("/admin/api/fd/getNextLevelId?special="+vm.isSpecialEdit).then(function(response){
                if(response.data.status == 0){
                    vm.editedItem.levelIdLoading = false;
                    vm.editedItem.levelId = response.data.data;
                }else{
                    vm.editedItem.levelIdLoading = false;
                    bus.$emit("showSnackMessage", "获取下一关卡id失败 " + response.data.msg, true);
                }
            })
        },
        deleteItem: function(item){
            this.editedItem =  Object.assign({}, item);
            this.isModifying = true;
            this.delCol();
        },

        delCol: function(){
            var vm = this;
            this.$confirm("确定要删除?").then(res => {
                if(res === true){
                    var form = new FormData();
                    form.append("id", this.editedItem.id);
                    axios.post("/admin/api/fd/delLevel", form, {
                        headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).then(function(response){
                        if(response.data.status == 0){
                             bus.$emit("删除成功");
                             vm.clearEdit();
                             vm.isModifying = false;
                             vm.reloadData();
                        }else{
                            bus.$emit("showSnackMessage", "删除错误: " + response.data.msg, true);                      
                        }
                    }).catch(function(err){
                        bus.$emit("showSnackMessage", "错误发生: " + err, true);
                    })
                }

            })
        },
        editItem: function(item){
            var index = this.items.indexOf(item);
            if (index !== -1){
                this.isModifying = true;
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            }else{
                this.isModifying = false;
            }
           
        },
        magnifyImage: function(url){
            this.magnifyImgUrl = url;
            this.magnifyImgDialog = true;
        },
       reloadData: function(){
           const {page, itemsPerPage} = this.options
           console.log(page)
           console.log(itemsPerPage)
           var vm = this;
           axios.get("/admin/api/fd/listLevels?page="+(page-1) + "&step=" + itemsPerPage + "&special="+vm.filterSpecial)
                .then(function(response){
                    vm.items = response.data.data.list;
                    vm.loading = false;
                    vm.serverLength = response.data.data.totalCnt;
                });

       },
       clearEdit: function(){
           this.editedItem = {             
               isSpecial:false,
               url: '',
               androidAssetFile:null,
               iosAssetFile:null,          
               levelId: '',
               FileId: '',
               LevelIdInt:0,
           }
       },
       cancelEdit: function(){
           this.clearEdit();
           this.editDialog = false;
       },
       calculateFileId: function(levelId, isSpecial){
           if(isSpecial){
                return this.$md5(levelId + "wwgmPW")

           }else{
               return this.$md5("sp"+levelId+"wwgmPW")
           }
       },

        getTimeStrFromTimestamp: function(timestamp){
            var s = new Date(timestamp)
            return s.toLocaleString("zh-CN")
        },

       newLevel: function(){
           var that = this;
           that.loading = true;
           var form = new FormData();
           form.append("fileId", this.calculateFileId(this.editedItem.levelId, this.editedItem.isSpecial))
           form.append("levelId", this.editedItem.levelId)
           form.append("levelIdInt", this.editedItem.levelId)
           form.append("isSpecial", this.isSpecialEdit)
           form.append("androidAssetFile", this.editedItem.androidAssetFile)
           form.append("iosAssetFile", this.editedItem.iosAssetFile)
        //    form.append("mainImg", this.editedItem.mainImg)
        //    form.append("subImg", this.editedItem.subImg)
           axios.post("/admin/api/fd/addLevel", form, {
              headers: {
                    'Content-Type': 'multipart/form-data'
              }
           }).then(function(response){
                console.log(response);
               if(response.data.status == 0){
                   that.reloadData();
                   bus.$emit("showSnackMessage", "新建成功", false);
                   that.loading = false;
                   that.clearEdit();
               }else{
                   that.loading = false;
                   bus.$emit("showSnackMessage", "新建失败" + response.data.msg, true);
               }
           })
       },
       saveEdit: function(){
           console.log(this.editedItem);
           if(!this.isEditing){
               this.newLevel();
           }
           this.editDialog = false;
       }
   },
  
   mounted: function(){
       this.reloadData();
   }
})
</script>
