<template>
 <v-container>
     <v-dialog v-model="eventDailog" max-width="1000px" persistent>
         <v-card>
             <v-card-title>{{dialogTitle}}活动</v-card-title>
             <v-card-text>
                 <v-container>
                     <v-row dense>
                         <v-col cols="3">
                             <v-text-field v-model="itemEdit.eventName" label="活动名称"></v-text-field>
                         </v-col>
                          <v-col cols="3">
                             <v-text-field v-model="itemEdit.eventId" label="活动ID"></v-text-field>
                         </v-col>
                     </v-row>                    
                    <v-row dense>
                        <v-col cols="5">
                            <v-menu
                                v-model="startMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="itemEdit.startTime"
                                    label="开始日期"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                >                                    
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="itemEdit.startTime"
                                @input="startMenu = false"
                            >                                     
                            </v-date-picker>
                            
                            </v-menu>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="5">
                            <v-menu
                                v-model="endMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="itemEdit.endTime"
                                    label="结束日期"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                >                                    
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="itemEdit.endTime"
                                @input="endMenu = false"
                            >                                     
                            </v-date-picker>
                            
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row dense>
                         <v-col cols="12">
                             <v-list dense>
                                 <v-list-group
                                    prepend-icon="mdi-translate"
                                 >
                                     <template v-slot:activator>
                                         <v-list-item-title>翻译{{Object.keys(itemEdit.localName).length}}</v-list-item-title>
                                     </template>
                                     <v-list-item v-for="(trans, lang) in itemEdit.localName" :key="lang">
                                         <v-row align="center" >
                                             <v-col>
                                                <v-text-field :value="getLangNameByCode(lang)" solo dense label="语言" disabled hide-details="true"></v-text-field>
                                             </v-col>
                                             <v-col>
                                                <v-text-field :value="trans" label="翻译" solo dense disabled hide-details="true"></v-text-field>
                                             </v-col>
                                             <v-col>
                                                 <v-btn outlined x-small color="red" @click="removeTrans(lang)">-</v-btn>
                                             </v-col>
                                         </v-row>
                                     </v-list-item>
                                     <v-list-item>
                                         <v-row align="center">
                                             <v-col>
                                                <v-select 
                                                    v-model="transEdit.lang"
                                                    label="语言"
                                                    :items = "langCandiate"
                                                    item-text = "name"
                                                    item-value = "code"
                                                ></v-select>
                                                 <!-- <v-text-field v-model="transEdit.lang" label="语言"></v-text-field> -->
                                             </v-col>
                                             <v-col>
                                                 <v-text-field v-model="transEdit.trans" label="翻译"></v-text-field>
                                             </v-col>
                                             <v-col>
                                                 <v-btn outlined x-small color="blue" @click="addTrans()">添加</v-btn>
                                             </v-col>
                                         </v-row>
                                     </v-list-item>
                                 </v-list-group>
                             </v-list>
                         </v-col>
                    </v-row>
                    <v-row dense>
                            <v-col cols="12">
                                <v-list dense>
                                    <v-list-group 
                                        @click="clearPackEdit()"
                                    prepend-icon="mdi-package-variant-closed">
                                        <template v-slot:activator>
                                            <v-list-item-title>包内容 {{itemEdit.packages.length}}</v-list-item-title>
                                        </template>
                                        <v-list-item
                                            v-for="(pack, index) in itemEdit.packages"
                                            :key="index"
                                        >
                                            <v-row align="center">
                                                <v-col cols="5">
                                                    <v-text-field v-model="pack.img" label="底图" disabled></v-text-field>                                
                                                </v-col>
                                                <v-spacer></v-spacer>
                                                <v-col cols="5">
                                                    <v-list-group
                                                        sub-group
                                                        @click="clearLevelEdit()"
                                                    >
                                                        <template v-slot:activator>
                                                            <v-list-item-title dense>关卡配置 {{pack.levels.length}}</v-list-item-title>
                                                        </template>
                                                        <v-list-item v-for="(lv, i) in pack.levels" :key="i">
                                                             <v-row class="mb-1" align="center">
                                                                <v-col cols="4">
                                                                    <v-text-field label="关卡id" v-model="lv.Id" solo dense disabled hide-details="true" height="1"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="4">
                                                                    <v-text-field label="难度" v-model="lv.difficalty" solo dense disabled hide-details="true" height="1" type="number"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="2">
                                                                    <v-btn color="red" outlined text @click="removeComponent(pack.levels, lv)" x-small><v-icon x-small>mdi-delete</v-icon></v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-list-item>
                                                        <v-list-item >
                                                            <v-row class="mt-1" align="center">
                                                                <v-col cols="4" align="center">
                                                                    <v-text-field label="关卡id" outlined dense v-model="levelEdit.Id" hide-details="true"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="4" align="center">
                                                                    <v-text-field label="难度" outlined dense v-model="levelEdit.difficalty" hide-details="true" type="number"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="2" align="center">
                                                                    <v-btn color="blue" outlined text @click="addComponent(pack.levels, levelEdit)" x-small>+</v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-list-item>
                                                    </v-list-group>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-btn outlined text color="red" @click="removeComponent(itemEdit.packages, pack)">删除</v-btn>
                                                </v-col>
                                            </v-row>
                                          
                                        </v-list-item>
                                        <v-list-item>
                                            
                                            <!-- <v-col cols="1">
                                                <p class="text grey--text">新增</p>
                                            </v-col> -->
                                            <v-row align="center">
                                                <v-col cols="5">
                                                     <v-file-input
                                                        label="底图"
                                                        accept="image/png, image/jpeg, image/bmp"
                                                        prepend-icon="mdi-camera"
                                                        @change="fileuploadChange"
                                                    >
                                                        <template v-slot:selection="{ text }">
                                                            <v-chip
                                                                small
                                                                label
                                                                color="primary"
                                                            >{{ text }}</v-chip>
                                                        </template>
                                                    </v-file-input>
                                                    <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                                                </v-col>
                                                <v-spacer></v-spacer>
                                                <v-col cols="5">
                                                    <v-list-group 
                                                        sub-group
                                                        @click="clearLevelEdit()"
                                                    >
                                                        <template v-slot:activator>
                                                            <v-list-item-title dense>关卡配置 {{packEdit.levels.length}}</v-list-item-title>
                                                        </template> 
                                                        <v-list-item v-for="(lv, i) in packEdit.levels" :key="i">
                                                            <v-row class="mb-1" align="center">
                                                                <v-col cols="4">
                                                                    <v-text-field label="关卡id" v-model="lv.Id" solo dense disabled hide-details="true" height="1"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="4">
                                                                    <v-text-field label="难度" v-model="lv.difficalty" solo dense disabled hide-details="true" height="1" type="number"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="2">
                                                                    <v-btn color="red" outlined text @click="removeComponent(packEdit.levels, lv)" x-small><v-icon x-small>mdi-delete</v-icon></v-btn>
                                                                </v-col>
                                                            </v-row>

                                                        </v-list-item>
                                                        <v-list-item >
                                                            <v-row class="mt-1" align="center">
                                                                <v-col cols="4" align="center">
                                                                    <v-text-field label="关卡id" outlined dense v-model="levelEdit.Id" hide-details="true"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="4" align="center">
                                                                    <v-text-field label="难度" outlined dense v-model="levelEdit.difficalty" hide-details="true" type="number"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="2" align="center">
                                                                    <v-btn color="blue" outlined text @click="addComponent(packEdit.levels, levelEdit);clearLevelEdit()" x-small>+</v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-list-item>
                                                    </v-list-group>
                                                    
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-btn outlined text color="blue" @click="addComponent(itemEdit.packages, packEdit);clearPackEdit()">新增</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-list-item>
                                        
                                        <v-spacer></v-spacer>
                                        <v-col cols="4">

                                        </v-col>
                                    </v-list-group>
                                </v-list>
                            </v-col>
                            
                    </v-row>
                 </v-container>
             </v-card-text>
             <v-card-actions>
                 <v-spacer></v-spacer>
                 <v-btn color="blue darken-1" @click="DailogCancel" text>取消</v-btn>
                 <v-btn color="blue darken-1" @click="DailogSave" text>确定</v-btn>
             </v-card-actions>
         </v-card>

     </v-dialog>

     <v-card loading="uploading">
         <v-toolbar flat>
             <v-toolbar-title>事件配置</v-toolbar-title>
             <v-divider vertical class="mx-2"></v-divider>
             <v-spacer></v-spacer>
             <v-btn outlined color="grey darken-2" @click="addEventDailog">新增</v-btn>
         </v-toolbar>
         <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="serverLength"
            :loading="loading"
            loading-text="加载中..."
            :search="search"
            class="elevation-2"
         >

         <template v-slot:item.modifyTime="{item}">
             <v-chip>{{getTimeStrFromTimestamp(item.modifyTime)}}</v-chip>
         </template>

         <template v-slot:item.actions="{item}">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
           >
                mdi-pencil
           </v-icon>
           <v-icon
                small
                @click="deleteItem(item)"
           >
                mdi-delete
           </v-icon>
         </template>

         </v-data-table>
     </v-card>
 </v-container>
</template>

<script>
import axios from 'axios'
import RegionUtils from '@/utils/regionUtils'
import {bus} from '@/main'

export default ({
    name : "Nonogram",
    components: {
        
    },
    data: function(){
        return {
            headers: [
                {
                    text: "Id",
                    align: "center",
                    sortable: false,
                    value: "eventId",
                    width: "10%",
                },
                {
                    text: "活动名称",
                    align: "center",
                    sortable: false,
                    value: "eventName",
                    width: "10%",
                },
                {
                    text: "开始日期",
                    align: "center",
                    sortable: false,
                    value: "startTime",
                    width: "10%",
                },
                {
                    text: "结束日期",
                    align: "center",
                    sortable: false,
                    value: "endTime",
                    width: "10%",
                },
                {
                    text: "修改日期",
                    align: "center",
                    sortable: false,
                    value: "modifyTime",
                    width: "10%",
                },
                {
                    text: "操作",
                    align: "center",
                    sortable: false,
                    value: "actions",
                    width: "10%",

                },
            ],
            loading:false,
            tabs: null,
            tabdata: null,
            eventDailog: false,
            uploading:false,
            editLang: '',
            startMenu:false,
            dialogDelete: false,
            search: "",
            options:{},
            items:[],
            endMenu:false,
            serverLength:0,
            editLocalName: '',
            isModifying: false,
            langCandiate: RegionUtils.lang,
            regionCandiate: RegionUtils.regions,
            transEdit: {
                lang: '',
                trans: '',
            },
            levelEdit: {
                Id: '',
                difficalty: 1,
            },
            packEdit:{
                img: '',
                levels:[],
            },
            itemEdit:{
                eventId: '',
                startTime: '',
                endTime: '',
                eventName: '',
                localName: {},
                packages: [],
            }
        }
    },
    computed: {
        //  isFreeLable:function(){
        //     return this.itemEdit.isFree ? "免费": "收费";
        // },
        dialogTitle: function(){
            return this.isModifying ? "修改":"新建";
        },
        // dialogTitle: function(){
        //     return this.isModifying?"修改Picture":"新建Picture";
        // },
    },
    watch: {
        options:{
            handler(){
                this.reloadData();
            },
            deep: true,
        },

    },

    methods: {
        getCNNameByCode: RegionUtils.getCNNameByCode.bind(RegionUtils),
        getLangNameByCode: RegionUtils.getLangNameByCode.bind(RegionUtils),
        addLocalName: function(){
            // this.itemEdit.localName[this.editLang] = this.editLocalName;
            this.$set(this.itemEdit.localName, this.editLang, this.editLocalName);
            console.log(this.itemEdit);
        },
     
        clearLevelEdit: function(){
            this.levelEdit =  {
                Id: '',
                difficalty: 1,
            };
        },
        clearPackEdit: function(){
            this.packEdit = {
                img: '',
                levels:[],
            };
            this.clearLevelEdit();
        },
        clearTransEdit: function(){
            this.transEdit = {
                lang: '',
                trans: '',
            };
        },
        addTrans: function(){
            this.itemEdit.localName[this.transEdit.lang] = this.transEdit.trans;
            this.clearTransEdit();
        },
        removeTrans: function(lang){
            this.$delete(this.itemEdit.localName, lang);
        },
        // addEditLevelToEditPack: function(){
        //     this.packItemEdit.levels.push({...this.levelEdit})
        // },
        addComponent: function(lst, elem){
            if ('difficalty' in elem){
                elem['difficalty'] = +elem['difficalty'];//处理类型问题，丑代码
            }
            lst.push(JSON.parse(JSON.stringify(elem))); //deep copy
        },
        removeComponent: function(lst, elem){
            console.log(lst);
            var index = lst.indexOf(elem);
            if(index != -1){
                lst.splice(index, 1);
            }
        },
        clearitemEdit: function(){
            this.isModifying = false;
            this.itemEdit = {
                eventId: '',
                startTime: '',
                endTime: '',
                eventName: '',
                localName: {},
                packages: [],
            };
            this.clearPackEdit();
        },
        modEventMeta: function(eventItem){
            this.itemEdit = Object.assign({}, eventItem);
            this.isModifying =true;
            this.eventDailog = true;
        },
        getIsFreeColor: function(isFree){
            return isFree?"green":"orange";
        },
        deleteLocalName: function(name){
            console.log(name);
            this.$delete(this.itemEdit.localName, name);
        },
        getTimeStrFromTimestamp(timestamp){
            var s = new Date(timestamp)
            return s.toLocaleString("zh-CN")
        },
        getIsFreeContent: function(isFree){
            return isFree?"免费":"付费";
        },
        addEventDailog: function(){
            this.clearitemEdit();
            this.eventDailog = true;
        },

        modCollection: function(col){
            this.itemEdit = col;
            this.isModifying = true;
            this.eventDailog = true;
            console.log(col);
        },

        delCollection: function(col){
            this.itemEdit = col;
            this.isModifying = true;
            this.delCol();
            console.log(col);
        },
        fileuploadChange: function(files){
            console.log(files)
            var formData = new FormData();
            formData.append("image", files);
            formData.append("product", "nonogram")
            var vm = this;
            axios.post("/admin/api/uploadFile", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.status == 0){
                    vm.packEdit.img = response.data.data.url;
                    //vm.itemEdit.hash = response.data.data.hash;
                }else{
                    bus.$emit("showSnackMessage", "图片上传失败 " + response.data.msg, true)
                }
              
            }).catch(function(){
                bus.$emit("showSnackMessage", "图片上传失败 ", true)
                console.log("upload failed");

            })
        },

        editItem: function(item){
            var index = this.items.indexOf(item);
            if (index !== -1){
                this.isModifying = true;
                this.itemEdit = Object.assign({}, item);
                this.eventDailog = true;
            }else{
                this.isModifying = false;
            }
           
        },

        deleteItem: function(item){
            console.log("delete item clicked");
            this.itemEdit = Object.assign({}, item);
            this.dialogDelete = true;
            var vm = this;
            this.$confirm("确定要删除?").then(res => {
                if(res === true){
                    var form = new FormData();
                    form.append("id", this.itemEdit.id);
                    axios.post("/admin/api/nonogram/delEvent", form, {
                        headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).then(function(response){
                        if(response.data.status == 0){
                             bus.$emit("删除成功");
                             vm.clearitemEdit();
                             vm.isModifying = false;
                             vm.reloadData();
                        }else{
                            bus.$emit("showSnackMessage", "删除错误: " + response.data.msg, true);                      
                        }
                    }).catch(function(err){
                        bus.$emit("showSnackMessage", "错误发生: " + err, true);
                    })
                }

            })
        },

        realAddEvent: function(eventItem){
            this.uploading = true;
            var vm = this;
            //eventItem.levelCnt = eventItem.levels.length;
            var formData = new FormData();
            for ( var key in eventItem ) {
                var item = eventItem[key];
                if (key == "packages"){
                    formData.append(key, JSON.stringify(item));
                    continue;
                }

                
                // if (Array.isArray(item)){
                //     for(var index in item){
                //         formData.append(key, item[index])
                //     }
                // }else 
                
                if (typeof item === "object" && item != null){
                    formData.append(key, JSON.stringify(item))
                }else{
                    formData.append(key, item)
                }
                
            }
            axios.post('/admin/api/nonogram/addEvent', formData, {
                headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(response){              
                console.log(response);
                vm.clearitemEdit();
                if(response.data.status == 0){
                    vm.isModifying = false;
                    vm.eventDailog = false;
                    vm.uploading = false;
                    bus.$emit("showSnackMessage", "新增成功")
                    vm.reloadData();
                }else{
                    bus.$emit("showSnackMessage", "新增/修改失败 " + response.data.status + " " + response.data.msg, true);
                    vm.uploading = false;
                }
                
            }).catch(function(error){
                console.log(error);
                vm.uploading = false;
                bus.$emit("showSnackMessage", "新增失败" + error, true)
                vm.reloadData();
            });
        },

        
        reloadData: function(){
            console.log("will reload data");
            var vm = this;
            const { page, itemsPerPage } = this.options
            axios.get("/admin/api/nonogram/listEvent?page="+(page-1)+'&step='+itemsPerPage).then(function(response){
                if(response.data.status == 0){
                    console.log(response);
                    vm.items = response.data.data.list;
                    vm.loading=false;
                    vm.serverLength = response.data.data.totalCnt;
                }else{
                    bus.$emit("showSnackMessage", "刷新event数据失败" + response.data.msg, true);
                }
            }).catch(function(err){
                bus.$emit("showSnackMessage", "刷新collection数据失败，出现异常 ：" +err);
            })
        },
        DailogSave: function(){
            this.realAddEvent(this.itemEdit);           
        },
        DailogCancel: function(){
            this.eventDailog = false;
            this.clearitemEdit();
        },
        isFreeChange: function(){
            this.itemEdit.coins = 0;
            this.itemEdit.price = 0;
            this.itemEdit.IAPId = "";
            
        }

    },

    mounted: function(){
        this.reloadData();
    },
})
</script>

<style >
.v-tab {
text-transform: none !important;
}
</style>
