<template>
  <v-container>
    <v-card class="ma-3">
      <v-tabs v-model="tab" grow>
        <v-tab col="3">Library配置</v-tab>
        <v-tab col="3">推荐神秘图配置</v-tab>
        <v-tab col="3">DailyTask配置</v-tab>
        <v-tab col="3">Collection配置</v-tab>
      </v-tabs>
    </v-card>
    <v-card class="ma-3">
      <v-tabs-items v-model="tab" class="mt-3" flat>
        <v-tab-item>
          <jigsaw-library-config/>
        </v-tab-item>
        <v-tab-item>
          <rec-mystery-config/>
        </v-tab-item>
          <v-tab-item>
          <dailytask-config/>
        </v-tab-item>
         <v-tab-item>
          <collection-config/>
        </v-tab-item>

      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
  //import axios from 'axios'
  import JigsawLibraryConfig from '@/components/jigsaw/JigsawLibraryConfig.vue'
  import DailyTaskConfig from '@/components/jigsaw/DailyTaskConfig.vue'
  import CollectionConfig from '@/components/jigsaw/CollectionConfig.vue'
  import RecMysteryConfig from '@/components/jigsaw/RecMysteryConfig.vue'

  export default {
    name: 'JigsawConfig',
    components: {
      'jigsaw-library-config': JigsawLibraryConfig,
      'dailytask-config': DailyTaskConfig,
      'collection-config': CollectionConfig,
      'rec-mystery-config': RecMysteryConfig,
    },
    data: function(){
      return {
        tab: null,
        libraryList: null
      }
    },
    mounted: function(){
      // axios.get("/admin/getuserinfo").then(function(response){
      //   if(response.data.status != 0){
      //     console.log(response.data)
      //   }

      // })
      
    }
  }
</script>
