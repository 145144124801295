<template>
    <v-container>
        <v-toolbar>
            <v-toolbar-title>{{collection.name}}({{collection.localName["zh-tw"]}})</v-toolbar-title>
            <v-divider vertical class="mx-2"></v-divider>
            <v-col class="3">
                可见地区:
                <v-chip v-for="(region, index) in collection.regions" :key="index">{{getCNNameByCode(region)}}</v-chip>
            </v-col>
             <!-- <v-col class="3">
                翻译:
                <v-chip v-for="(localName, lang) in collection.localName" :key="lang">{{getLangNameByCode(lang)}}:{{localName}}</v-chip>
            </v-col> -->
            <v-img max-height="38px" max-width="60px" :src="collection.cover" class="rounded-lg" ></v-img>
            <v-spacer></v-spacer>
            <v-btn outlined color="green" class="mx-2" @click="makefirst">置顶</v-btn>
            <v-btn outlined color="primary" class="mx-2" @click="modCollection">修改</v-btn>
            <v-btn outlined color="red" class="mx-2" @click="delCollection">删除</v-btn>
        </v-toolbar>
         <v-dialog width="800px" v-model="magnifyImgDialog">
            <v-card>
                <v-img :src="magnifyImgUrl"></v-img>
            </v-card>

        </v-dialog>

        <v-dialog 
                v-model="dialog" 
                persistent
                max-width="500px"
               >
                    <v-card>
                        <v-img class="mb-1" v-if="editedItem.url != ''"
                            height="200"
                            :src="editedItem.url"
                        >

                        </v-img>

                        <v-card-title > 修改Picture </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-file-input
                                            label="上传图片"
                                            filled
                                            accept="image/png, image/jpeg, image/bmp"
                                            prepend-icon="mdi-camera"
                                            @change="fileuploadChange"
                                        >
                                            <template v-slot:selection="{ text }">
                                                <v-chip
                                                    small
                                                    label
                                                    color="primary"
                                                >{{ text }}</v-chip>
                                            </template>
                                        </v-file-input>
                                    </v-col>
    
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Url"
                                            v-model="editedItem.url"
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Hash"
                                            v-model="editedItem.hash"
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-checkbox
                                            v-model="editedItem.isFree"
                                            :label="isFreeLable"
                                            @change="isFreeChange"
                                        >
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="editedItem.price"
                                            :disabled="editedItem.isFree"
                                            label="价格"
                                            :items = "priceCandiate"
                                            item-text = "price"
                                            item-value = "price"
                                        >
                                        </v-select>
                                        
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select
                                            v-model="editedItem.collections"
                                            :items="collectionCandiate"
                                            label="图集"
                                            item-text="name"
                                            item-value="name"
                                            multiple
                                            chips
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            v-model="editedItem.regions"
                                            label="可见地区"
                                            :items="regionCandiate"
                                            item-text="nameCN"
                                            item-value="alpha2"
                                            multiple
                                            chips
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="cancelPicture"
                            >
                                取消
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="savePicture"
                            >
                                保存
                            </v-btn>
                        </v-card-actions>
                    </v-card>
           </v-dialog>     

        <v-card>
            <v-data-table
                :headers = "headers"
                :items = "items"
                :options.sync="options"
                :server-items-length="serverLength"
                :loading="loading"
                loading-text="加载中..."
                class="elevation-2"
            >
                <template v-slot:item.isFree="{ item }">
                    <v-chip
                        :color="getIsFreeColor(item.isFree)"
                    >{{getIsFreeContent(item.isFree)}}</v-chip>
                </template>
                <template v-slot:item.collections="{ item }">
                    <v-chip v-for="collection, index in item.collections" :key="index">{{collection}}</v-chip>
                </template>
                <template v-slot:item.modifyTime="{ item }">
                    <v-chip>{{getTimeStrFromTimestamp(item.modifyTime)}}</v-chip>
                </template>
                <template v-slot:item.url="{ item }">
                    <v-img @click="magnifyImage(item)" class="rounded-lg" max-width="60" max-height="38" :src="item.url+'?x-oss-process=style/w_200'"/>
                    <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
                </template>
                <template v-slot:item.regions="{ item }">
                        <v-chip v-for="region, index in item.regions" :key="index">{{getCNNameByCode(region)}}</v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                            small
                            class="mr-2"
                            @click="editPicItem(item)"
                    >
                            mdi-pencil
                    </v-icon>
                    <v-icon
                            small
                            @click="deletePicItem(item)"
                    >
                            mdi-delete
                    </v-icon>
                </template>
       
            </v-data-table>
        </v-card>
    </v-container>
    
</template>

<script>
import axios from 'axios'
import RegionUtils from '@/utils/regionUtils'
import {bus} from '@/main'


export default {
    name: 'CollectionDetail',
    props:{
        collection: Object,
    },

    watch: {
        options:{
            handler(){
                this.reloadData();
            },
            deep: true,
        },

    },

    
    data: function(){
        return {
            magnifyImgDialog:false,
            magnifyImgUrl: '',
            isModifying: false,
            dialogDelete:false,
            isFreeChange:false,
            isFreeLable: '',
            regionCandiate: RegionUtils.regions,
            collectionCandiate:[
                {name:"Scene"},
                {name:"Carton"},
                {name:"Masterpiece"},
                {name: "Plants"},
                {name: "Animal"},
                {name: "Pets"},

            ],
            priceCandiate: [
                {tier: 1, price: 0.99, currency: "USD"},
                {tier: 2, price: 1.99, currency: "USD"},
                {tier: 3, price: 2.99, currency: "USD"},
                {tier: 4, price: 3.99, currency: "USD"},
                {tier: 5, price: 4.99, currency: "USD"},
            ],
            headers: [
                {
                    text: "Id",
                    align: "center",
                    sortable: false,
                    value: "id",
                    width: "10%",

                },
                {
                   text: "Hash",
                   align: "center",
                   value: "hash", 
                   width: "10%",
                },
                {
                    text: "预览",
                    align: "center",
                    value: "url",
                    width: "10%",
                },
                {
                    text: "地域",
                    align: "center",
                    value: "regions",
                    width: "20%",
                },
                {
                    text: "图集",
                    align: "center",
                    value: "collections",
                    width: "20%",
                },
                {
                    text: "费用",
                    align: "center",
                    value: "isFree",
                    width: "5%",
                },
                {
                    text: "价格",
                    align: "center",
                    value: "price",
                    width: "5%",
                    
                },
                {
                    text: "修改时间",
                    align: "center",
                    value: "modifyTime",
                    width: "10%",
                },
                {
                    text: "操作",
                    align: "center",
                    value: "actions",
                    width: "10%",
                }
            ],
            editedItem: {
                url: '',
                hash: '',
                isFree: true,
                price: 0,
                collections: [],
                regions:['AA'],
            },
            items: [],
            loading: true,
            dialog: false,
            options:{},
            serverLength:0,
            imageList: [],
        }
    },

    methods: {
            getCNNameByCode: RegionUtils.getCNNameByCode.bind(RegionUtils),
            getLangNameByCode: RegionUtils.getLangNameByCode.bind(RegionUtils),
            makefirst: function(){
                var form = new FormData()
                var that = this;
                form.append("id", that.collection.id);
                axios.post("/admin/api/makecolfirst", form).then(function(response){
                    if(response.data.status == 0){
                        bus.$emit("showSnackMessage", "置顶成功");
                    }else{
                        bus.$emit("showSnackMessage", "置顶失败 " + response.data.msg, true);
                    }
                    that.$emit("reloadAllData");
                })
            },

            modPicture: function(){
                var vm = this;
                var formData = new FormData();
                for ( var key in this.editedItem ) {
                    var item = this.editedItem[key];
                    if (Array.isArray(item)){
                        for(var index in item){
                            formData.append(key, item[index])
                        }
                    }else{
                        formData.append(key, item)
                    }
                    
                }
                axios.post('/admin/api/modPicture', formData, {
                    headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(function(response){              
                    console.log(response);
                    vm.clearEditedItem();
                    vm.isModifying = false;
                    vm.dialog = false;
                    bus.$emit("showSnackMessage", "修改成功")
                    vm.reloadData();
                }).catch(function(error){
                    console.log(error);
                    bus.$emit("showSnackMessage", "修改失败 "+error, true);
                    vm.reloadData();
                });
            },
            fileuploadChange: function(files){
                console.log(files)
                var formData = new FormData();
                formData.append("image", files);
                var vm = this;
                axios.post("/admin/api/uploadFile", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(function(response){
                    if(response.data.status == 0){
                        vm.editedItem.url = response.data.data.url;
                        vm.editedItem.hash = response.data.data.hash;
                    }else{
                        bus.$emit("showSnackMessage", "图片上传失败 " + response.data.msg, true)
                    }
                
                }).catch(function(){
                    bus.$emit("showSnackMessage", "图片上传失败 ", true)
                    console.log("upload failed");

                })
            },
            editPicItem: function(item){
                    var index = this.items.indexOf(item);
                    if (index !== -1){
                        this.isModifying = true;
                        this.editedItem = Object.assign({}, item);
                        this.dialog = true;
                    }else{
                        this.isModifying = false;
                    }
            
                },
                savePicture: function(){
            if(this.isModifying){
                this.modPicture();
            }else{
                this.addPicture();
            }
            
            },
            cancelPicture: function(){
                this.clearEditedItem();
                this.isModifying = false;
                this.dialog = false;
            },
            deletePicItem: function(item){
               var vm = this;
                this.$confirm('确定要删除么?').then(res => {
                if(res===true){
                    var form = new FormData();
                    form.append("id", item.id);
                    axios.post("/admin/api/delPicture", form, {
                            headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }).then(function(response){
                            if(response.data.status == 0){

                                bus.$emit("删除成功");
                                vm.focus = vm.editedItem.date;
                                vm.clearEditedItem();
                                vm.isModifying = false;
                                vm.dailyTaskDailog = false;
                                vm.reloadData();
                            }else{
                                bus.$emit("showSnackMessage", "删除错误: " + response.data.msg, true);
                            }

                    }).catch(function(err){
                        bus.$emit("showSnackMessage", "错误发生: " + err, true);
                    })
                }
            }); 
            },
            clearEditedItem: function(){
                this.editedItem =  {
                    url: '',
                    hash: '',
                    isFree: true,
                    price: 0,
                    collections: [],
                    regions:['AA'],
                };
            },
            magnifyImage: function(item){
                this.magnifyImgUrl = item.url;
                this.magnifyImgDialog = true;
            },
            getTimeStrFromTimestamp(timestamp){
                var s = new Date(timestamp)
                return s.toLocaleString("zh-CN")
            },
            pagination: function(page){
                console.log("pagination param is :" + page);
            },
            getIsFreeColor: function(isFree){
                return isFree?"green":"orange";
            },
            getIsFreeContent: function(isFree){
                return isFree?"免费":"付费";
            },
            modCollection: function(){
                this.$emit("modifyCollection", this.collection)
            },
            delCollection: function(){
                this.$emit("deleteCollection",this.collection)
            },
            reloadData: function(){
                const { page, itemsPerPage } = this.options
                console.log(page)
                console.log(itemsPerPage)
                var vm = this;
                axios.get('/admin/api/listPicture?page='+(page-1)+'&count='+itemsPerPage + "&collection=" + vm.collection.name)
                    .then(function(response){
                        vm.items = response.data.data.list;
                        vm.loading=false;
                        vm.serverLength = response.data.data.totalCnt;
                        
                });
            },
            getColCandinate: function(){
                var vm = this;
                axios.get("/admin/api/listCollection")
                .then(function(response){
                    if(response.data.status == 0){
                        vm.collectionCandiate = response.data.data;
                    }
                });
            },
    },

    mounted: function(){
        this.getColCandinate();
        this.reloadData();
    }
}
</script>
