var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-dialog',{attrs:{"width":"800px"},model:{value:(_vm.imageViewer),callback:function ($$v) {_vm.imageViewer=$$v},expression:"imageViewer"}},[_c('v-card',[_c('v-img',{attrs:{"src":_vm.imageViewerUrl}})],1)],1),_c('v-dialog',{attrs:{"max-width":"1000px","persistent":""},model:{value:(_vm.eventDailog),callback:function ($$v) {_vm.eventDailog=$$v},expression:"eventDailog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.dialogTitle)+"战令活动")]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"战令名称"},model:{value:(_vm.itemEdit.passName),callback:function ($$v) {_vm.$set(_vm.itemEdit, "passName", $$v)},expression:"itemEdit.passName"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"战令id"},model:{value:(_vm.itemEdit.passId),callback:function ($$v) {_vm.$set(_vm.itemEdit, "passId", $$v)},expression:"itemEdit.passId"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-checkbox',{attrs:{"label":"是否为默认"},model:{value:(_vm.itemEdit.isDefault),callback:function ($$v) {_vm.$set(_vm.itemEdit, "isDefault", $$v)},expression:"itemEdit.isDefault"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"开始日期","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.itemEdit.startTime),callback:function ($$v) {_vm.$set(_vm.itemEdit, "startTime", $$v)},expression:"itemEdit.startTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startMenu),callback:function ($$v) {_vm.startMenu=$$v},expression:"startMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.startMenu = false}},model:{value:(_vm.itemEdit.startTime),callback:function ($$v) {_vm.$set(_vm.itemEdit, "startTime", $$v)},expression:"itemEdit.startTime"}})],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"结束日期","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.itemEdit.endTime),callback:function ($$v) {_vm.$set(_vm.itemEdit, "endTime", $$v)},expression:"itemEdit.endTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endMenu),callback:function ($$v) {_vm.endMenu=$$v},expression:"endMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.endMenu = false}},model:{value:(_vm.itemEdit.endTime),callback:function ($$v) {_vm.$set(_vm.itemEdit, "endTime", $$v)},expression:"itemEdit.endTime"}})],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-file-input',{attrs:{"label":"入口图","accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-camera"},on:{"change":function (files) { return _vm.fileuploadChange(files, 'passEntrance'); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-file-input',{attrs:{"label":"战令名称图","accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-camera"},on:{"change":function (files) { return _vm.fileuploadChange(files, 'passNameImg'); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-file-input',{attrs:{"label":"战令背景图","accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-camera"},on:{"change":function (files) { return _vm.fileuploadChange(files, 'passBG'); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-file-input',{attrs:{"label":"奖励列表头背景","accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-camera"},on:{"change":function (files) { return _vm.fileuploadChange(files, 'passListTopBG'); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-file-input',{attrs:{"label":"奖励列表背景","accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-camera"},on:{"change":function (files) { return _vm.fileuploadChange(files, 'passListBG'); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-file-input',{attrs:{"label":"奖励列表底部背景","accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-camera"},on:{"change":function (files) { return _vm.fileuploadChange(files, 'passListBottomBG'); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}])})],1)],1),_c('v-divider'),_c('v-row',{attrs:{"dense":""}},[_c('v-textarea',{attrs:{"label":"免费奖励","outlined":"","rows":"1","auto-grow":""},model:{value:(_vm.freeRewardJsonStr),callback:function ($$v) {_vm.freeRewardJsonStr=$$v},expression:"freeRewardJsonStr"}})],1),_c('v-row',{attrs:{"dense":""}},[_c('v-textarea',{attrs:{"label":"付费奖励","outlined":"","rows":"1","auto-grow":""},model:{value:(_vm.premiumRewardJsonStr),callback:function ($$v) {_vm.premiumRewardJsonStr=$$v},expression:"premiumRewardJsonStr"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.DailogCancel}},[_vm._v("取消")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.DailogSave}},[_vm._v("确定")])],1)],1)],1),_c('v-card',{attrs:{"loading":"uploading"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("战令配置")]),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.addEventDailog}},[_vm._v("新增")])],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.serverLength,"loading":_vm.loading,"loading-text":"加载中...","search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.passNameImg",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"rounded-lg",attrs:{"max-width":"60","max-height":"38","src":item.passNameImg+'?x-oss-process=style/w_200'},on:{"click":function($event){return _vm.viewImage(item.passNameImg)}}})]}},{key:"item.passEntrance",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"rounded-lg",attrs:{"max-width":"60","max-height":"38","src":item.passEntrance+'?x-oss-process=style/w_200'},on:{"click":function($event){return _vm.viewImage(item.passEntrance)}}})]}},{key:"item.passBG",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"rounded-lg",attrs:{"max-width":"60","max-height":"38","src":item.passBG+'?x-oss-process=style/w_200'},on:{"click":function($event){return _vm.viewImage(item.passBG)}}})]}},{key:"item.passListTopBG",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"rounded-lg",attrs:{"max-width":"60","max-height":"38","src":item.passListTopBG+'?x-oss-process=style/w_200'},on:{"click":function($event){return _vm.viewImage(item.passListTopBG)}}})]}},{key:"item.passListBG",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"rounded-lg",attrs:{"max-width":"60","max-height":"38","src":item.passListBG+'?x-oss-process=style/w_200'},on:{"click":function($event){return _vm.viewImage(item.passListBG)}}})]}},{key:"item.isDefault",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.isDefault),callback:function ($$v) {_vm.$set(item, "isDefault", $$v)},expression:"item.isDefault"}})]}},{key:"item.passListBottomBG",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"rounded-lg",attrs:{"max-width":"60","max-height":"38","src":item.passListBottomBG+'?x-oss-process=style/w_200'},on:{"click":function($event){return _vm.viewImage(item.passListBottomBG)}}})]}},{key:"item.modifyTime",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(_vm.getTimeStrFromTimestamp(item.modifyTime)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }