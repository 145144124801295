<template>
        <v-container>

        <v-card class="ma-3">
            <v-tabs v-model="tab" grow>
                <v-tab>战令配置</v-tab>
                <v-tab>球杆配置</v-tab>
                <v-tab>巧克配置</v-tab>
            </v-tabs>
            <v-card class="ma-3">
                <v-tabs-items v-model="tab" class="mt-3" flat>
                    <v-tab-item>
                        <poolpass-config/>
                    </v-tab-item>
                    <v-tab-item>
                        <poolrival-cue-config/>
                    </v-tab-item>
                    <v-tab-item>
                        <poolrival-chalk-config/>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-card>
    </v-container>
   </template>
   
   <script>
   import PoolRivalPassConfig from '@/components/poolrival/PoolRivalPassConfig.vue'
   import PoolRivalCueConfig from '@/components/poolrival/PoolRivalCueConfig.vue'
   import PoolRivalChalkConfig from '../components/poolrival/PoolRivalChalkConfig.vue';
   
   
   export default ({
       name : "PoolRival",
       components: {
           'poolpass-config' : PoolRivalPassConfig,
           'poolrival-cue-config' : PoolRivalCueConfig,
            'poolrival-chalk-config' : PoolRivalChalkConfig,
       },
       
       computed: {
           //  isFreeLable:function(){
           //     return this.itemEdit.isFree ? "免费": "收费";
           // },
           dialogTitle: function(){
               return this.isModifying ? "修改":"新建";
           },
           // dialogTitle: function(){
           //     return this.isModifying?"修改Picture":"新建Picture";
           // },
       },
       watch: {
           options:{
               handler(){
                   this.reloadData();
               },
               deep: true,
           },
   
       },

       data: function(){
           return {
                tab: null,
                tabs: null,           
                tabdata: null,
           }
       },
   
       
   
       mounted: function(){
           //this.reloadData();
       },
   })
   </script>
   
   <style >
   .v-tab {
   text-transform: none !important;
   }
   </style>   