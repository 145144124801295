<template>
    <v-container>
        <v-dialog width="800px" v-model="magnifyImgDialog">
            <v-card>
                <v-img :src="magnifyImgUrl"></v-img>
            </v-card>

        </v-dialog>
        <v-dialog
            v-model="addItemDialog"
            persistent
            max-width="580px"
        >
        <v-card :loading="addItemLoading">
            <v-card-title>新增关卡</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-file-input
                            filled
                            prepend-icon="mdi-android"
                            label="安卓 asset"
                            v-model="editedItem.androidAssetFile"
                        >

                        </v-file-input>
                    </v-col>
                     <v-col cols="12">
                        <v-file-input
                            filled
                            prepend-icon="mdi-apple"
                            label="iOS asset"
                            v-model="editedItem.iosAssetFile"
                        >

                        </v-file-input>
                    </v-col>
                    <!-- <v-col cols="12">
                        <v-file-input
                            label="上传主图"
                            v-model="editedItem.mainImg"
                            filled
                            accept="image/png, image/jpeg, image/bmp"
                            prepend-icon="mdi-camera"
                        >
                            <template v-slot:selection="{ text }">
                                <v-chip
                                    small
                                    label
                                    color="primary"
                                >{{ text }}</v-chip>
                            </template>
                        </v-file-input>
                    </v-col>
                    <v-col cols="12">
                            <v-file-input
                            label="上传副图"
                            v-model="editedItem.subImg"
                            filled
                            accept="image/png, image/jpeg, image/bmp"
                            prepend-icon="mdi-camera"
                        >
                            <template v-slot:selection="{ text }">
                                <v-chip
                                    small
                                    label
                                    color="primary"
                                >{{ text }}</v-chip>
                            </template>
                        </v-file-input>
                    </v-col> -->
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="cancelAddItem">取消</v-btn>
                <v-btn text color="primary" @click="saveAddItem">确定</v-btn>
            </v-card-actions>
        </v-card>

        </v-dialog>
        <v-toolbar>
           <v-toolbar-title>{{event.eventName}}</v-toolbar-title>
           <v-divider vertical class="mx-2"></v-divider>
           <v-col cols="3">
               开始日期:
               <v-chip>{{event.startDate}}</v-chip>
           </v-col>
           <v-col cols="3">
               结束日期:
               <v-chip>{{event.endDate}}</v-chip>
           </v-col>
           <v-img max-height="38px" max-width="60px" class="rounded-lg" :src="event.cover"></v-img>
           <v-spacer></v-spacer>
           <v-btn color="primary" class="mx-2" outlined @click="modifyEventMeta">修改</v-btn>
           <v-btn color="red" class="mx-2" outlined @click="delEvent">删除</v-btn>
           <v-btn color="primary" class="mx-2" outlined @click="addItem">加关</v-btn>
        </v-toolbar>
        <v-card class="mt-2">
            <v-data-table
                :headers="headers"
                :items = "event.levels"
                :items-per-page="-1"
                class="elevation-2"
            >
            <template v-slot:item.levelId="{item}"
            >
                {{event.levels.indexOf(item) + 1}}
            </template>
            <template v-slot:item.androidAsset="{item}">
                <v-icon @click="downloadItem({url:item.androidAsset, label: item.levelId + '_and.asset'} )">mdi-file</v-icon>
            </template>
             <template v-slot:item.iosAsset="{item}">
                <v-icon @click="downloadItem({url:item.iosAsset, label: item.levelId + '_ios.asset'} )">mdi-file</v-icon>
            </template>
            <!-- <template v-slot:item.subImg="{item}">
                <v-img class="rounded-lg" max-width="60" max-height="38" :src="item.subImg+'?x-oss-process=style/w_200'" @click="magnifyImage(item.subImg)"></v-img>
            </template> -->

            <template v-slot:item.actions="{item}">
                <v-icon
                 class="mr-2"
                 @click="delItem(item)"
                > mdi-delete</v-icon>
                <v-icon
                 @click="upItem(item)"
                >
                  {{event.levels.indexOf(item) != 0 ? "mdi-arrow-up-bold" : "mdi-minus-circle-outline"}}
                   
                </v-icon>
                <v-icon
                 @click="downItem(item)"
                >
                {{event.levels.indexOf(item) == event.levels.length-1 ? "mdi-minus-circle-outline" : "mdi-arrow-down-bold"}}
                  mdi-arrow-down-bold
                </v-icon>
            </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import axios from "axios";
import {bus} from "@/main"


export default ({
    name : "EventDetail",
    props:{
        event: Object
    },
    data : ()=>{
        return {
            addItemDialog:false,
            addItemLoading:false,
            magnifyImgDialog:false,
            magnifyImgUrl:'',
            editedItem:{
                androidAssetFile: null,
                iosAssetFile:null,
                // mainImg:null,
                // subImg:null,
            },
            headers: [
                {
                    text: "序号",
                    value: "levelId",
                    align: "center",
                },
                {
                    text: "安卓 Asset",
                    value: "androidAsset",
                    align: "center",
                },
                {
                    text: "iOS Asset",
                    value: "iosAsset",
                    align: "center",
                },
                // {
                //     text: "副图",
                //     value: "subImg",
                //     align: "center",
                // },
                {
                    text: "行动",
                    value: "actions",
                    align: "center",
                }
            ],
        }
    },
    methods: {
        downloadItem: function({ url, label }) {
            console.log(url)
            console.log(label)
            axios.get(url, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = label
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        },
        clearEdit: function(){
            this.editedItem = {
                androidAssetFile: null,
                iosAssetFile:null,
            }
        },
        magnifyImage: function(url){
            this.magnifyImgUrl = url;
            this.magnifyImgDialog = true;
        },
        modifyEventMeta: function(){
            this.$emit("modEventMeta", this.event);
        },
        modifyEvent: function(){
            this.$emit("modEvent", this.event);
        },
        upItem: function(item){
            let index = this.event.levels.indexOf(item);
            if (index > 0){
                let from = this.event.levels[index];
                let to = this.event.levels[index -1];
                this.$set(this.event.levels, index, to);
                this.$set(this.event.levels, index- 1, from);
            }
            this.modifyEvent();
        },
        downItem: function(item){
            let index = this.event.levels.indexOf(item);
            if (index != -1 && index < this.event.levels.length - 1){
                let from = this.event.levels[index];
                let to = this.event.levels[index + 1];
                this.$set(this.event.levels, index, to);
                this.$set(this.event.levels, index + 1, from)
            }
            this.modifyEvent();
        },
        delItem: function(item){
            var index = this.event.levels.indexOf(item);
            if (index > -1){
                this.event.levels.splice(index, 1);
            }
            this.modifyEvent();
        },
        delEvent: function(){
            this.$emit("delEvent", this.event);
        },
        addItem: function(){
            this.clearEdit();
            this.addItemDialog = true;
        },
        cancelAddItem: function(){
            this.clearEdit();
            this.addItemDialog = false;
        },
        saveAddItem: function(){
            
            var vm = this;
            vm.addItemLoading = true;
            var formData = new FormData()
            formData.append("androidAssetFile", vm.editedItem.androidAssetFile);
            formData.append("iosAssetFile", vm.editedItem.iosAssetFile);

            //formData.append("subImg", vm.editedItem.subImg);
            axios.post("/admin/api/fd/addEventLevel", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function(response){
                console.log(response);
                if(response.data.status == 0){
                    vm.addItemLoading = false;
                    vm.addItemDialog = false;
                    vm.event.levels.push({"androidAsset": response.data.data.androidAsset,"iosAsset": response.data.data.iosAsset, "fileId": response.data.data.fileId});
                    bus.$emit("showSnackMessage", "关卡数据上传成功");
                    vm.modifyEvent();
                    
                }else{
                    vm.addItemLoading = false;
                    vm.addItemDialog = false;
                    bus.$emit("showSnackMessage", "关卡数据上传失败" + response.data.msg, true);
                }
            })
        },


    },
  
})
</script>
