<template>
    <v-container>
        <v-card max-width="500" class="mx-auto mt-16">
            <v-card-title>登录</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field label="Email" v-model="email"></v-text-field>
                        </v-col>              
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field 
                                label="Password" 
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show1 ? 'text' : 'password'"
                                @click:append="show1 = !show1"
                                v-model="password"></v-text-field>
                        </v-col>
                    </v-row>
                
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn color="primary" @click="doLogin">登录</v-btn>
            </v-card-actions>
        </v-card>
    
    </v-container>
</template>

<script>
import {bus} from "@/main"
import axios from 'axios'
export default {
    name: "Login",
    data: function(){
        return {
            email: "",
            password: "",
            show1: false,
        }
    },
    methods: {
        testJump: function(){
            console.log("test jump btn clicked");
            this.$router.push("/")
        },
        doLogin: function(){
            var formdata = new FormData();
            formdata.append("email", this.email)
            formdata.append("password", this.password)
            var vm = this;
            axios.post("/admin/login", formdata,  {
                headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(response){
                console.log(response.data);
                if(response.data.status !== 0){
                    bus.$emit("showSnackMessage", response.data.msg, true);
                }else{                    
                    bus.$emit("userInfoChange", response.data.data, true);
                    vm.$router.push("/");
                }
            })
        }
    }
   
}
</script>
