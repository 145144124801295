<template>
    <v-container>
        <v-dialog v-model="itemDailog" max-width="1000px" persistent>
            <v-card>
                <v-card-title>{{dialogTitle}}巧克配置</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row dense>
                            <v-col cols="3">
                                <v-text-field v-model="itemEdit.chalkId" label="巧克ID"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-file-input
                                    label="静态展示图"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-file-image"
                                    @change="(files) => fileuploadChange(files, 'img')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row dense>
                            <v-chip color="primary" class="ma-2">
                                    普通 BUNDLE
                            </v-chip>
                            <v-col cols="3">
                                <v-file-input
                                    label="unity bundle android"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-android"
                                    @change="(files) => fileuploadChange(files, 'bundle_and')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                            <v-col cols="3">
                                <v-file-input
                                    label="unity bundle ios"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-apple"
                                    @change="(files) => fileuploadChange(files, 'bundle_ios')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row dense>
                            <v-chip color="red" class="ma-2">
                                IDLE BUNDLE
                            </v-chip>
                            <v-col cols="3">
                                <v-file-input
                                    label="idle unity bundle android"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-android"
                                    @change="(files) => fileuploadChange(files, 'idle_bundle_and')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                            <v-col cols="3">
                                <v-file-input
                                    label="idle unity bundle ios"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-apple"
                                    @change="(files) => fileuploadChange(files, 'idle_bundle_ios')"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                                                       <!-- <v-text-field v-model="packEdit.img" label="底图"></v-text-field>                                 -->
                            </v-col>
                        </v-row>                    
                       <v-row dense>
                            <v-textarea
                                    v-model="propertyJsonStr"
                                    label="属性"
                                    outlined
                                    rows="1"
                                    auto-grow
                            ></v-textarea>                              
                       </v-row>
                       
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" @click="DailogCancel" text>取消</v-btn>
                    <v-btn color="blue darken-1" @click="DailogSave" text>确定</v-btn>
                </v-card-actions>
            </v-card>
   
        </v-dialog>
        <v-card loading="uploading">
            <v-toolbar flat>
                <v-toolbar-title>巧克配置</v-toolbar-title>
                <v-divider vertical class="mx-2"></v-divider>
                <v-spacer></v-spacer>
                <v-btn outlined color="grey darken-2" @click="addItemDailog">新增</v-btn>
            </v-toolbar>
            <v-data-table
              :headers="headers"
               :items="items"
               :options.sync="options"
               :server-items-length="serverLength"
               :loading="loading"
               loading-text="加载中..."
               class="elevation-2"
            >
            <template v-slot:item.img="{ item }">
                <v-img @click="viewImage(item.img)" class="rounded-lg" max-width="60" max-height="38" :src="item.img+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
            </template>

            <template v-slot:item.modifyTime="{item}">
                <v-chip>{{getTimeStrFromTimestamp(item.modifyTime)}}</v-chip>
            </template>

            <template v-slot:item.actions="{item}">
                 <v-icon
                   small
                   class="mr-2"
                   @click="editItem(item)"
              >
                   mdi-pencil
              </v-icon>
              <v-icon
                   small
                   @click="deleteItem(item)"
              >
                   mdi-delete
              </v-icon>
            </template>
               
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from 'axios'
import {bus} from '@/main'

export default({
    name: 'PoolRivalChalkConfig',
    components: {
    },
    data: function(){
        return {
            headers: [
                   {
                       text: "chalkId",
                       align: "center",
                       sortable: false,
                       value: "chalkId",
                       width: "10%",
                   },
                  
                   {
                       text: "静态展示图",
                       align: "center",
                       sortable: false,
                       value: "img",
                       width: "10%",
                   },
                   {
                       text: "属性",
                       align: "center",
                       sortable: false,
                       value: "property",
                       width: "5%",
                   },
                   {
                       text: "idle_bundle_android",
                       align: "center",
                       sortable: false,
                       value: "idle_bundle_and",
                       width: "5%",
                   },
                   {
                       text: "idle_bundle_ios",
                       align: "center",
                       sortable: false,
                       value: "idle_bundle_ios",
                       width: "5%",
                   },
                   {
                       text: "bundle_android",
                       align: "center",
                       sortable: false,
                       value: "bundle_and",
                       width: "5%",
                   },
                   {
                       text: "bundle_ios",
                       align: "center",
                       sortable: false,
                       value: "bundle_ios",
                       width: "5%",
                   },
                   {
                       text: "修改时间",
                       align: "center",
                       sortable: false,
                       value: "modifyTime",
                       width: "5%",
                   },
                   {
                       text: "操作",
                       align: "center",
                       sortable: false,
                       value: "actions",
                       width: "10%",
   
                   },
               ],
               loading: false,
               isModifying : false,
               uploading:false,
               itemDailog: false,
               dialogDelete: false,
               search: "",
               propertyJsonStr: "{}",
               options:{},
               items:[],
               serverLength:0,
               itemEdit:{
                   _id: "",
                   chalkId: "",
                   img: "",
                   property: {},
                   bundle_and: "",
                   bundle_ios: "",
                   idle_bundle_and: "",
                   idle_bundle_ios: "",
               }

        }
    },
    computed: {
           //  isFreeLable:function(){
           //     return this.itemEdit.isFree ? "免费": "收费";
           // },
           dialogTitle: function(){
               return this.isModifying ? "修改":"新建";
           },
           // dialogTitle: function(){
           //     return this.isModifying?"修改Picture":"新建Picture";
           // },
       },
    methods: {
        viewImage: function(url){
            bus.$emit('viewImage', url)
        },

        getTimeStrFromTimestamp(timestamp){
               var s = new Date(timestamp * 1000)
               return s.toLocaleString("zh-CN")
        },

        fileuploadChange: function(files, point){
               console.log(files)
               console.log(point)
               var formData = new FormData();
               formData.append("image", files);
               formData.append("product", "poolrivalchalk")
               if(point == "img" || point == "bundle_and" || point == "bundle_ios" || point == "idle_bundle_and" || point == "idle_bundle_ios"){
                   if(this.itemEdit.chalkId == ""){
                       bus.$emit("showSnackMessage", "请先填写cueId", true);
                       return;
                   }
                   formData.append("target", point)
                   formData.append("chalkId", this.itemEdit.chalkId);
               }
               
               var vm = this;
               axios.post("/admin/api/uploadFile", formData, {
                   headers: {
                       'Content-Type': 'multipart/form-data'
                   }
               }).then(function(response){
                   if(response.data.status == 0){
                       vm.itemEdit[point] = response.data.data.url;
                       //vm.itemEdit.hash = response.data.data.hash;
                   }else{
                       bus.$emit("showSnackMessage", "图片上传失败 " + response.data.msg, true)
                   }
                 
               }).catch(function(){
                   bus.$emit("showSnackMessage", "图片上传失败 ", true)
                   console.log("upload failed");
   
               })
           },

        reloadData: function(){
               this.loading = true
               console.log("will reload data");
               var vm = this;
               const { page, itemsPerPage } = this.options
               axios.get("/admin/api/poolrival/listPoolChalk?page="+(page-1)+'&step='+itemsPerPage).then(function(response){
                   if(response.data.status == 0){
                       console.log(response);
                       vm.items = response.data.data.list;
                       vm.loading=false;        
                       vm.serverLength = response.data.data.totalCnt;
                   }else{
                        console.log(response.data.status);
                       bus.$emit("showSnackMessage", "刷新ChalkList数据失败" + response.data.msg, true);
                   }
               }).catch(function(err){
                   bus.$emit("showSnackMessage", "刷新ChalkList数据失败，出现异常 ：" +err);
               })
           },
           clearItemEdit: function(){
               this.itemEdit = {
                   _id: "",
                   chalkId: "",
                   img: "",
                   property: {},
                   bundle_and: "",
                   bundle_ios: "",
                   idle_bundle_and: "",
                   idle_bundle_ios: "",
               };
               this.refreshJsonStr();
            },
            addItemDailog: function(){
                    this.clearItemEdit();
                    this.itemDailog = true;
            },
            refreshJsonStr: function(){
                this.propertyJsonStr = JSON.stringify(this.itemEdit.property, null, 2);
            },
   

           editItem: function(item){
               var index = this.items.indexOf(item);
               if (index !== -1){
                   this.isModifying = true;
                   this.itemEdit = Object.assign({}, item);
                   this.refreshJsonStr();
                   this.itemDailog = true;
               }else{
                   this.isModifying = false;
               }
              
           },
           deleteItem: function(item){
               console.log("delete item clicked");
               this.itemEdit = Object.assign({}, item);
               this.dialogDelete = true;
               var vm = this;
               this.$confirm("确定要删除?").then(res => {
                   if(res === true){
                       var form = new FormData();
                       form.append("_id", this.itemEdit._id);
                       axios.post("/admin/api/poolrival/delPoolChalk", form, {
                           headers: {
                                   'Content-Type': 'application/x-www-form-urlencoded'
                           }
                       }).then(function(response){
                           if(response.data.status == 0){
                                bus.$emit("删除成功");
                                vm.clearItemEdit();
                                vm.isModifying = false;
                                vm.reloadData();
                           }else{
                               bus.$emit("showSnackMessage", "删除错误: " + response.data.msg, true);                      
                           }
                       }).catch(function(err){
                           bus.$emit("showSnackMessage", "错误发生: " + err, true);
                       })
                   }
   
               })
           },
           realAddItem: function(item){
               this.uploading = true;
               var vm = this;
               //eventItem.levelCnt = eventItem.levels.length;
               var formData = new FormData();
               formData.append("data", JSON.stringify(item));
               console.log(formData);
            //    for ( var key in eventItem ) {
            //        var item = eventItem[key];
            //        if (key == "packages"){
            //            formData.append(key, JSON.stringify(item));
            //            continue;
            //        }
   
                   
            //        // if (Array.isArray(item)){
            //        //     for(var index in item){
            //        //         formData.append(key, item[index])
            //        //     }
            //        // }else 
                   
            //        if (typeof item === "object" && item != null){
            //            formData.append(key, JSON.stringify(item))
            //        }else{
            //            formData.append(key, item)
            //        }
                   
            //    }
               axios.post('/admin/api/poolrival/addPoolChalk', formData, {
                   headers: {
                           'Content-Type': 'application/x-www-form-urlencoded'
                   }
               }).then(function(response){              
                   console.log(response);
                   vm.clearItemEdit();
                   if(response.data.status == 0){
                       vm.isModifying = false;
                       vm.eventDailog = false;
                       vm.uploading = false;
                       bus.$emit("showSnackMessage", "新增成功")
                       vm.reloadData();
                   }else{
                       bus.$emit("showSnackMessage", "新增/修改失败 " + response.data.status + " " + response.data.msg, true);
                       vm.uploading = false;
                   }
                   
               }).catch(function(error){
                   console.log(error);
                   vm.uploading = false;
                   bus.$emit("showSnackMessage", "新增失败" + error, true)
                   vm.reloadData();
               });
           },
           DailogSave: function(){
               this.realAddItem(this.itemEdit);           
           },
           DailogCancel: function(){
               this.itemDailog = false;
               this.clearItemEdit();
           },
    },
   
    watch:{
        options:{
            handler: function(){
                this.reloadData();
            },
            deep: true
        },
        propertyJsonStr: function(){
               try{
                   this.itemEdit.property = JSON.parse(this.propertyJsonStr);
               }catch(e){
                   bus.$emit("showSnackMessage", "json格式错误 ", true)
                   this.freeRewardJsonStr = JSON.stringify(this.itemEdit.property);
                   console.log(e);
               }
        },
    },
    mounted: function(){
           this.reloadData();
    },
})
</script>