var MiscUtils = {
    rules:{
        urlRules:[
            // return true 表示通过
            v => !!v || '不能为空',
            v => v.startsWith("https://") || "URL格式错误，应该是https://开头"
        ],
    }
}

export default MiscUtils;