<template>
    <v-container>
        <v-sheet height="64">
            <v-toolbar flat>
                <v-btn outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                >
                    今天
                </v-btn>
                <v-btn 
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="prev"
                ><v-icon small> mdi-chevron-left</v-icon></v-btn>
                <v-btn 
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="next"
                ><v-icon small> mdi-chevron-right</v-icon></v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{$refs.calendar.title}}                   
                </v-toolbar-title>
            </v-toolbar>
        </v-sheet>
        <v-sheet
            height="500">
            <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
               
                @click:date = "configDay"
                :type="type"
                :events="events"
                @change="changeEvent"

            >
                <template v-slot:day="{past, date}">
                    <v-row
                        class="fill-height ma-0"
                    >
                    <template  v-if="ifTaskExist(date)">
                            <v-img v-for="elem, index in taskData[date]" :src="'https://gamecdn.orange-social.com/fd/04f3a946f8093ee63509a91c36c91cad.jpg?x-oss-process=style/w_200'" class="rounded-lg mx-1" max-width="60" max-height="38" :key="index" @click="modifyTask(elem)" ><v-chip x-small :color="getRegionChipColor(elem)">{{getRegionStr(elem)}}</v-chip> </v-img>
                    </template>
                    </v-row>
                </template>

            </v-calendar>
        </v-sheet>
        <v-dialog
            max-width="600px"
            v-model="dailyTaskDailog"
            
            persistent
        >

            <v-card
                loading="addloading"
            >
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-img class="mb-1" v-if="editedItem.asset != ''"
                            height="200"
                            src='https://gamecdn.orange-social.com/fd/04f3a946f8093ee63509a91c36c91cad.jpg'
                        >
                        </v-img>
                    </v-col>
                    <!-- <v-col cols="6">
                        <v-img class="mb-1" v-if="editedItem.subUrl != ''"
                            height="200"
                            :src="editedItem.subUrl"
                        >
                        </v-img>
                    </v-col> -->
                </v-row>
              

                <v-card-title >{{getDateStrFromFocus(this.focus)}}</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row dense>
                             <v-col cols="12">
                                <v-file-input
                                    filled
                                    prepend-icon="mdi-android"
                                    label="上传asset"
                                    v-model="editedItem.androidAssetFile"
                                >

                                </v-file-input>
                            </v-col>
                             <v-col cols="12">
                                <v-file-input
                                    filled
                                    prepend-icon="mdi-apple"
                                    label="上传asset"
                                    v-model="editedItem.iosAssetFile"
                                >

                                </v-file-input>
                            </v-col>
                            <!-- <v-col cols="12">
                                <v-file-input
                                    label="主图"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    v-model="editedItem.mainImg"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input
                                    label="副图"
                                    accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera"
                                    v-model="editedItem.subImg"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            small
                                            label
                                            color="primary"
                                        >{{ text }}</v-chip>
                                    </template>
                                </v-file-input>
                            </v-col> -->

                            <v-col cols="12">
                                <v-text-field
                                    label="安卓 URL"
                                    v-model="editedItem.androidAsset"
                         
                                    disabled
                                ></v-text-field>
                            </v-col>
                              <v-col cols="12">
                                <v-text-field
                                    label="iOS URL"
                                    v-model="editedItem.iosAsset"
                         
                                    disabled
                                ></v-text-field>
                            </v-col>
                            
                            
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="editedItem.regions"
                                    label="可见地区"
                                    :items="regionCandiate"
                                    item-text="nameCN"
                                    item-value="alpha2"
                                    multiple
                                    chips
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="isModifying" 
                        class="red darken-a"
                        text
                        @click="deleteTask"
                    >
                        删除
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="cancelTask"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="saveTask"
                    >
                        保存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import RegionUtils from '@/utils/regionUtils'
import {bus} from '@/main'
import axios from 'axios'

export default {
    name: "FDDailyTaskConfig",
    data: function(){
        return {
           focus: '',
           dailyTaskDailog: false,
           type: 'month',
           title: "unknown",
           events: [],
           isModifying: false,
           taskData:{},
           start:'',
           end: '',
           addloading:false,
           numberkey:0,
           editedItem: {
                androidAssetFile: null,
                iosAssetFile:null,
                date: '',
                regions:['AA'],
            },
            regionCandiate: RegionUtils.regions,
        }
    },
    methods:{
        getCNNameByCode: RegionUtils.getCNNameByCode.bind(RegionUtils),
        ifTaskExist: function(date){
          
            return !!this.taskData[date];
        },
        getRegionChipColor: function(task){
            if (task.regions.includes("AA")){
                return "green";
            }else{
                return "red";
            }
        },
        deleteTask: function(){
            var vm = this;
            this.$confirm('确定要删除么?').then(res => {
                if(res===true){
                    var form = new FormData();
                    form.append("id", this.editedItem.id);
                    axios.post("/admin/api/fd/delDailyTask", form, {
                            headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }).then(function(response){
                            if(response.data.status == 0){

                                bus.$emit("删除成功");
                                vm.focus = vm.editedItem.date;
                                vm.clearEditedItem();
                                vm.isModifying = false;
                                vm.dailyTaskDailog = false;
                                vm.reloadData();
                            }else{
                                bus.$emit("showSnackMessage", "删除错误: " + response.data.msg, true);
                            }

                    }).catch(function(err){
                        bus.$emit("showSnackMessage", "错误发生: " + err, true);
                    })
                }
            });
        },
        modifyTask: function(task){
            this.focus = task.date;
            this.editedItem = Object.assign({}, task);   
            this.isModifying = true;
            this.dailyTaskDailog = true;
        },
        getRegionStr: function(task){
            var str = "";
            var that = this;
            task.regions.forEach(function(item){
               str += that.getCNNameByCode(item);
            })
            return str;
        },
        getDateStrFromFocus: function(date){
            if (!date){
                return "日期错误";
            }
            var xxx = date.split('-');
            return xxx[0] + "年" + xxx[1] + "月" + xxx[2] + "日";
        },
        clearEditedItem: function(){
            this.editedItem ={
                androidAssetFile: null,
                iosAssetFile:null,
                date: '',
                regions:['AA'],
            };
        },
        // fileuploadChange: function(files){
        //     console.log(files)
        //     var formData = new FormData();
        //     formData.append("image", files);
        //     var vm = this;
        //     axios.post("/admin/api/uploadFile", formData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         }
        //     }).then(function(response){
        //         if(response.data.status == 0){
        //             vm.editedItem.url = response.data.data.url;
        //             vm.editedItem.hash = response.data.data.hash;
        //         }else{
        //             bus.$emit("showSnackMessage", "图片上传失败 " + response.data.msg, true)
        //         }
              
        //     }).catch(function(){
        //         bus.$emit("showSnackMessage", "图片上传失败 ", true)
        //         console.log("upload failed");

        //     })
        // },

        cancelTask: function(){
            this.dailyTaskDailog = false;
            this.isModifying = false;
            this.clearEditedItem();
        },
        saveTask: function(){
            if(!this.isModifying){
                this.addTask();
            }else{
                this.modTask();
            }
        },
        prev: function(){
        
            this.$refs.calendar.prev();
        },
        next: function(){
            this.$refs.calendar.next()
        },

        configDay(date){
            console.log(date);
            this.focus = date.date;
            this.editedItem.date = date.date;
            console.log(this.editedItem.date);
            this.dailyTaskDailog = true;
        },

        setToday(){
            this.focus = '';
        },

        // modTask: function(){
        //     var vm = this;
        //     var formData = new FormData();
        //      for ( var key in this.editedItem ) {
        //         var item = this.editedItem[key];
        //         if (Array.isArray(item)){
        //             for(var index in item){
        //                 formData.append(key, item[index])
        //             }
        //         }else{
        //             formData.append(key, item)
        //         }
                
        //     }
        //     axios.post('/admin/api/modDailyTask', formData, {
        //         headers: {
        //                 'Content-Type': 'application/x-www-form-urlencoded'
        //         }
        //     }).then(function(response){              
        //         console.log(response);
        //         vm.dailyTaskDailog = false;
        //         vm.isModifying = false;
        //         vm.focus = vm.clearEditedItem.date;
        //         vm.clearEditedItem();   
        //         bus.$emit("showSnackMessage", "修改成功")
        //         vm.reloadData();
        //     }).catch(function(error){
        //         console.log(error);
        //         bus.$emit("showSnackMessage", "修改失败 "+error, true);
        //         vm.reloadData();
        //     });
        // },

        addTask: function(){
            var vm = this;
            vm.addloading = true;
            var formData = new FormData();
            for ( var key in this.editedItem ) {
                var item = this.editedItem[key];
                if (Array.isArray(item)){
                    for(var index in item){
                        formData.append(key, item[index])
                    }
                }else{
                    formData.append(key, item)
                }
                
            }
            axios.post('/admin/api/fd/addDailyTask', formData, {
                headers: {
                        'Content-Type': 'multipart/form-data'
                }
            }).then(function(response){    
                if(response.data.status==0){
                    console.log(response);
                    vm.focus = vm.editedItem.date;
                    vm.clearEditedItem();
                    vm.isModifying = false;
                    vm.addloading = false;
                    vm.dailyTaskDailog = false;
                    bus.$emit("showSnackMessage", "新增成功")
                    vm.reloadData();
                }else{
                    vm.addloading = false;
                    bus.$emit("showSnackMessage", "新增失败: " + response.data.msg);
                }          
                
                //vm.changeEvent();
            }).catch(function(error){
                console.log(error);
                vm.addloading = false;
                bus.$emit("showSnackMessage", "新增失败" + error, true)
                //vm.reloadData();
            });
        },

        reloadData: function(){
            const events = [];
            console.log(this.start);
            console.log(this.end);
            var vm = this;
            axios.get("/admin/api/fd/listDailyTask?start="+this.start.date+"&end=" +this.end.date).then(
                function(response){
                    var list = response.data.data;
                    var obj ={}
                    console.log(list);
                    list.forEach(function(elem){
                        var key = elem.date;
                        obj[key] = obj[key] || []
                        obj[key].push(elem)
                    });
                    vm.taskData = obj;
                  
                }
            )
          

            this.events = events
        },

        changeEvent ({ start, end }) {
            this.start = start;
            this.end=end;
            this.reloadData();
            
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
    },
    created: function(){

    },
    mounted: function(){
        this.$refs.calendar.checkChange();
        //this.$refs.calendar.checkChange();
    }
}
</script>
