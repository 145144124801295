import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VuetifyConfirm from 'vuetify-confirm';
import router from './router'
import md5 from 'js-md5';

Vue.config.productionTip = false
export const bus = new Vue();
Vue.use(VuetifyConfirm,{vuetify})
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
Vue.prototype.$login = false
Vue.prototype.$md5=md5
