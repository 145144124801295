<template>
        <v-container>

        <v-card class="ma-3">
            <v-tabs v-model="tab" grow>
                <v-tab>战令配置</v-tab>
                <v-tab>用户浏览</v-tab>
            </v-tabs>
            <v-card class="ma-3">
                <v-tabs-items v-model="tab" class="mt-3" flat>
                    <v-tab-item>
                        <poolpass-config/>
                    </v-tab-item>
                    <v-tab-item>
                        <poolclash-userprofile/>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-card>
    </v-container>
   </template>
   
   <script>
   import PoolPassConfig from '@/components/poolclash/PoolPassConfig.vue'
   import PoolClashUserProfile from '@/components/poolclash/PoolClashUserProfile.vue'
   import axios from 'axios'
   import RegionUtils from '@/utils/regionUtils'
   import {bus} from '@/main'
   
   export default ({
       name : "PoolClash",
       components: {
           'poolpass-config' : PoolPassConfig,
           'poolclash-userprofile' : PoolClashUserProfile,
       },
       data: function(){
           return {
               headers: [
                   {
                       text: "Id",
                       align: "center",
                       sortable: false,
                       value: "eventId",
                       width: "10%",
                   },
                   {
                       text: "活动名称",
                       align: "center",
                       sortable: false,
                       value: "eventName",
                       width: "10%",
                   },
                   {
                       text: "开始日期",
                       align: "center",
                       sortable: false,
                       value: "startTime",
                       width: "10%",
                   },
                   {
                       text: "结束日期",
                       align: "center",
                       sortable: false,
                       value: "endTime",
                       width: "10%",
                   },
                   {
                       text: "修改日期",
                       align: "center",
                       sortable: false,
                       value: "modifyTime",
                       width: "10%",
                   },
                   {
                       text: "操作",
                       align: "center",
                       sortable: false,
                       value: "actions",
                       width: "10%",
   
                   },
               ],
               loading:false,
               tab: null,
               tabs: null,
            
               tabdata: null,
               eventDailog: false,
               uploading:false,
               editLang: '',
               startMenu:false,
               dialogDelete: false,
               search: "",
               options:{},
               items:[],
               endMenu:false,
               serverLength:0,
               editLocalName: '',
               isModifying: false,
               langCandiate: RegionUtils.lang,
               regionCandiate: RegionUtils.regions,
               transEdit: {
                   lang: '',
                   trans: '',
               },
               levelEdit: {
                   Id: '',
                   difficalty: 1,
               },
               packEdit:{
                   img: '',
                   levels:[],
               },
               itemEdit:{
                   eventId: '',
                   startTime: '',
                   endTime: '',
                   eventName: '',
                   localName: {},
                   packages: [],
               }
           }
       },
       computed: {
           //  isFreeLable:function(){
           //     return this.itemEdit.isFree ? "免费": "收费";
           // },
           dialogTitle: function(){
               return this.isModifying ? "修改":"新建";
           },
           // dialogTitle: function(){
           //     return this.isModifying?"修改Picture":"新建Picture";
           // },
       },
       watch: {
           options:{
               handler(){
                   this.reloadData();
               },
               deep: true,
           },
   
       },
   
       methods: {
           getCNNameByCode: RegionUtils.getCNNameByCode.bind(RegionUtils),
           getLangNameByCode: RegionUtils.getLangNameByCode.bind(RegionUtils),
           addLocalName: function(){
               // this.itemEdit.localName[this.editLang] = this.editLocalName;
               this.$set(this.itemEdit.localName, this.editLang, this.editLocalName);
               console.log(this.itemEdit);
           },
         
           clearLevelEdit: function(){
               this.levelEdit =  {
                   Id: '',
                   difficalty: 1,
               };
           },
           clearPackEdit: function(){
               this.packEdit = {
                   img: '',
                   levels:[],
               };
               this.clearLevelEdit();
           },
           clearTransEdit: function(){
               this.transEdit = {
                   lang: '',
                   trans: '',
               };
           },
           addTrans: function(){
               this.itemEdit.localName[this.transEdit.lang] = this.transEdit.trans;
               this.clearTransEdit();
           },
           removeTrans: function(lang){
               this.$delete(this.itemEdit.localName, lang);
           },
           // addEditLevelToEditPack: function(){
           //     this.packItemEdit.levels.push({...this.levelEdit})
           // },
           addComponent: function(lst, elem){
               if ('difficalty' in elem){
                   elem['difficalty'] = +elem['difficalty'];//处理类型问题，丑代码
               }
               lst.push(JSON.parse(JSON.stringify(elem))); //deep copy
           },
           removeComponent: function(lst, elem){
               console.log(lst);
               var index = lst.indexOf(elem);
               if(index != -1){
                   lst.splice(index, 1);
               }
           },
           clearitemEdit: function(){
               this.isModifying = false;
               this.itemEdit = {
                   eventId: '',
                   startTime: '',
                   endTime: '',
                   eventName: '',
                   localName: {},
                   packages: [],
               };
               this.clearPackEdit();
           },
           modEventMeta: function(eventItem){
               this.itemEdit = Object.assign({}, eventItem);
               this.isModifying =true;
               this.eventDailog = true;
           },
           getIsFreeColor: function(isFree){
               return isFree?"green":"orange";
           },
           deleteLocalName: function(name){
               console.log(name);
               this.$delete(this.itemEdit.localName, name);
           },
           getTimeStrFromTimestamp(timestamp){
               var s = new Date(timestamp)
               return s.toLocaleString("zh-CN")
           },
           getIsFreeContent: function(isFree){
               return isFree?"免费":"付费";
           },
           addEventDailog: function(){
               this.clearitemEdit();
               this.eventDailog = true;
           },
   
           modCollection: function(col){
               this.itemEdit = col;
               this.isModifying = true;
               this.eventDailog = true;
               console.log(col);
           },
   
           delCollection: function(col){
               this.itemEdit = col;
               this.isModifying = true;
               this.delCol();
               console.log(col);
           },
           fileuploadChange: function(files){
               console.log(files)
               var formData = new FormData();
               formData.append("image", files);
               formData.append("product", "nonogram")
               var vm = this;
               axios.post("/admin/api/uploadFile", formData, {
                   headers: {
                       'Content-Type': 'multipart/form-data'
                   }
               }).then(function(response){
                   if(response.data.status == 0){
                       vm.packEdit.img = response.data.data.url;
                       //vm.itemEdit.hash = response.data.data.hash;
                   }else{
                       bus.$emit("showSnackMessage", "图片上传失败 " + response.data.msg, true)
                   }
                 
               }).catch(function(){
                   bus.$emit("showSnackMessage", "图片上传失败 ", true)
                   console.log("upload failed");
   
               })
           },
   
           editItem: function(item){
               var index = this.items.indexOf(item);
               if (index !== -1){
                   this.isModifying = true;
                   this.itemEdit = Object.assign({}, item);
                   this.eventDailog = true;
               }else{
                   this.isModifying = false;
               }
              
           },
   
           deleteItem: function(item){
               console.log("delete item clicked");
               this.itemEdit = Object.assign({}, item);
               this.dialogDelete = true;
               var vm = this;
               this.$confirm("确定要删除?").then(res => {
                   if(res === true){
                       var form = new FormData();
                       form.append("id", this.itemEdit.id);
                       axios.post("/admin/api/nonogram/delEvent", form, {
                           headers: {
                                   'Content-Type': 'application/x-www-form-urlencoded'
                           }
                       }).then(function(response){
                           if(response.data.status == 0){
                                bus.$emit("删除成功");
                                vm.clearitemEdit();
                                vm.isModifying = false;
                                vm.reloadData();
                           }else{
                               bus.$emit("showSnackMessage", "删除错误: " + response.data.msg, true);                      
                           }
                       }).catch(function(err){
                           bus.$emit("showSnackMessage", "错误发生: " + err, true);
                       })
                   }
   
               })
           },
   
           realAddEvent: function(eventItem){
               this.uploading = true;
               var vm = this;
               //eventItem.levelCnt = eventItem.levels.length;
               var formData = new FormData();
               for ( var key in eventItem ) {
                   var item = eventItem[key];
                   if (key == "packages"){
                       formData.append(key, JSON.stringify(item));
                       continue;
                   }
   
                   
                   // if (Array.isArray(item)){
                   //     for(var index in item){
                   //         formData.append(key, item[index])
                   //     }
                   // }else 
                   
                   if (typeof item === "object" && item != null){
                       formData.append(key, JSON.stringify(item))
                   }else{
                       formData.append(key, item)
                   }
                   
               }
               axios.post('/admin/api/nonogram/addEvent', formData, {
                   headers: {
                           'Content-Type': 'application/x-www-form-urlencoded'
                   }
               }).then(function(response){              
                   console.log(response);
                   vm.clearitemEdit();
                   if(response.data.status == 0){
                       vm.isModifying = false;
                       vm.eventDailog = false;
                       vm.uploading = false;
                       bus.$emit("showSnackMessage", "新增成功")
                       vm.reloadData();
                   }else{
                       bus.$emit("showSnackMessage", "新增/修改失败 " + response.data.status + " " + response.data.msg, true);
                       vm.uploading = false;
                   }
                   
               }).catch(function(error){
                   console.log(error);
                   vm.uploading = false;
                   bus.$emit("showSnackMessage", "新增失败" + error, true)
                   vm.reloadData();
               });
           },
   
           
           reloadData: function(){
               console.log("will reload data");
               var vm = this;
               const { page, itemsPerPage } = this.options
               axios.get("/admin/api/nonogram/listEvent?page="+(page-1)+'&step='+itemsPerPage).then(function(response){
                   if(response.data.status == 0){
                       console.log(response);
                       vm.items = response.data.data.list;
                       vm.loading=false;
                       vm.serverLength = response.data.data.totalCnt;
                   }else{
                       bus.$emit("showSnackMessage", "刷新event数据失败" + response.data.msg, true);
                   }
               }).catch(function(err){
                   bus.$emit("showSnackMessage", "刷新collection数据失败，出现异常 ：" +err);
               })
           },
           DailogSave: function(){
               this.realAddEvent(this.itemEdit);           
           },
           DailogCancel: function(){
               this.eventDailog = false;
               this.clearitemEdit();
           },
           isFreeChange: function(){
               this.itemEdit.coins = 0;
               this.itemEdit.price = 0;
               this.itemEdit.IAPId = "";
               
           }
   
       },
   
       mounted: function(){
           //this.reloadData();
       },
   })
   </script>
   
   <style >
   .v-tab {
   text-transform: none !important;
   }
   </style>   