<template>
 <v-container>
     <v-card loading="uploading">
         <v-toolbar flat>
             <v-toolbar-title>事件配置</v-toolbar-title>
             <v-divider vertical class="mx-2"></v-divider>
             <v-spacer></v-spacer>
             <v-btn outlined color="grey darken-2" @click="addEventDailog">新增</v-btn>
             <v-dialog v-model="eventDailog" max-width="500px" persistent>
                 <v-card>
                      <v-img class="mb-1" v-if="editedItem.cover != ''"
                            height="200"
                            :src="editedItem.cover"
                        >

                        </v-img>
                        <v-card-title >{{ dialogTitle }}</v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-text-field
                                            label="活动名称"
                                            v-model="editedItem.eventName"
                                        ></v-text-field>
                                    </v-col>
                                
                                    <v-col cols="12">
                                        <v-file-input
                                            label="上传封面图"
                                            filled
                                            accept="image/png, image/jpeg, image/bmp"
                                            prepend-icon="mdi-camera"
                                            @change="fileuploadChange"
                                        >
                                            <template v-slot:selection="{ text }">
                                                <v-chip
                                                    small
                                                    label
                                                    color="primary"
                                                >{{ text }}</v-chip>
                                            </template>
                                        </v-file-input>
                                    </v-col>
                                    <v-col></v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="5">
                                        <v-menu
                                            v-model="startMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="editedItem.startDate"
                                                label="开始日期"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            >                                    
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="editedItem.startDate"
                                            @input="startMenu = false"
                                        >                                     
                                        </v-date-picker>
                                       
                                        </v-menu>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="5">
                                        <v-menu
                                            v-model="endMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="editedItem.endDate"
                                                label="结束日期"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            >                                    
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="editedItem.endDate"
                                            @input="endMenu = false"
                                        >                                     
                                        </v-date-picker>
                                       
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <!-- <v-col cols="3">
                                        <v-checkbox
                                            v-model="editedItem.isFree"
                                            label="免费"
                                            @change="isFreeChange"
                                        >
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-select
                                            v-model="editedItem.price"
                                            :disabled="editedItem.isFree"
                                            label="内购价格"
                                            :items = "priceCandiate"
                                            item-text = "price"
                                            item-value = "price"
                                        >
                                        </v-select>
                                        
                                    </v-col>
                                    <v-spacer></v-spacer>
                                      <v-col cols="4">
                                        <v-text-field v-model="editedItem.coins" label="金币数量" :disabled="editedItem.isFree"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="内购ID" v-model="editedItem.IAPId" :disabled="editedItem.isFree"></v-text-field>
                                    </v-col>
   -->
                                  
                                    <v-col cols="12">
                                        <v-autocomplete
                                            v-model="editedItem.regions"
                                            label="可见地区"
                                            :items="regionCandiate"
                                            item-text="nameCN"
                                            item-value="alpha2"
                                            multiple
                                            chips
                                        ></v-autocomplete>
                                    </v-col>
                                  
                                </v-row>
                                <!-- <v-row dense>
                                    <v-col cols="1"><span>翻译:</span></v-col>
                                    <v-col cols="10">
                                        <v-row>
                                            <template v-for="(value, name) in editedItem.localName" >
                                                <v-chip close @click:close="deleteLocalName(name)" :key="name">{{getLangNameByCode(name)}}:{{value}}</v-chip>

                                            </template>
                                        </v-row>
    
                                        <v-row>
                                            <v-col cols="4">
                                                
                                                <v-select 
                                                     v-model="editLang"
                                                    label="语言"
                                                    :items = "langCandiate"
                                                    item-text = "name"
                                                    item-value = "code"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="4">
                                            <v-text-field v-model="editLocalName" label="翻译" cols="2"></v-text-field>
                                            </v-col>
                                        
                                            <v-col cols="2">
                                            <v-btn color="primary" outlined @click="addLocalName" small>添加</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row> -->
                            
                                
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="DailogCancel"
                            >
                                取消
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="DailogSave"
                            >
                                保存
                            </v-btn>
                        </v-card-actions>
                    </v-card>


             </v-dialog>
         </v-toolbar>
         <v-tabs v-model="tabs" 
               background-color="deep-purple accent-4"
                dark
               next-icon="mdi-arrow-right-bold-box-outline"
                prev-icon="mdi-arrow-left-bold-box-outline"
                show-arrows
          >
            <v-tabs-slider color="yellow"></v-tabs-slider>
             <v-tab col="4"  v-for="(item, index) in tabdata" :key="index">
                 {{item.eventName}}
                 <!-- <template v-if="item.isHide">
                     <v-icon>mdi-eye-off</v-icon>
                 </template>
                 <template v-if="!item.isFree">
                     <v-icon>mdi-currency-usd</v-icon>
                 </template> -->
             </v-tab>
         </v-tabs>
         <v-tabs-items v-model="tabs" class="mt-3" flat>
             <v-tab-item v-for="(item, index) in tabdata" :key="index">
                 <event-detail v-bind:event="item" v-on:modEventMeta="modEventMeta" v-on:modEvent="modEvent" v-on:delEvent="delEvent" v-on:reloadAllData="reloadData" />
                 
             </v-tab-item>
         </v-tabs-items>
     </v-card>
 </v-container>
</template>

<script>
import axios from 'axios'
import RegionUtils from '@/utils/regionUtils'
import {bus} from '@/main'
import EventDetail from '@/components/fd/EventDetail.vue'

export default ({
    name : "FDEventConfig",
    components: {
        'event-detail': EventDetail,
    },
    data: function(){
        return {
            tabs: null,
            tabdata: null,
            eventDailog: false,
            uploading:false,
            editLang: '',
            startMenu:false,
            endMenu:false,
            editLocalName: '',
            isModifying: false,
            langCandiate: RegionUtils.lang,
            regionCandiate: RegionUtils.regions,
            priceCandiate: [
                {tier: 1, price: 0.99, currency: "USD"},
                {tier: 2, price: 1.99, currency: "USD"},
                {tier: 3, price: 2.99, currency: "USD"},
                {tier: 4, price: 3.99, currency: "USD"},
                {tier: 5, price: 4.99, currency: "USD"},
            ],
            editedItem:{
                eventName: '',
                cover: '',
                startDate: '',
                endDate: '',
                levelCnt: 0,
                levels:[],
                regions:["AA"],
            }
        }
    },
    computed: {
        //  isFreeLable:function(){
        //     return this.editedItem.isFree ? "免费": "收费";
        // },
        dialogTitle: function(){
            return this.isModifying ? "修改":"新建";
        },
        // dialogTitle: function(){
        //     return this.isModifying?"修改Picture":"新建Picture";
        // },
    },
    methods: {
        getCNNameByCode: RegionUtils.getCNNameByCode.bind(RegionUtils),
        getLangNameByCode: RegionUtils.getLangNameByCode.bind(RegionUtils),
        addLocalName: function(){
            // this.editedItem.localName[this.editLang] = this.editLocalName;
            this.$set(this.editedItem.localName, this.editLang, this.editLocalName);
            console.log(this.editedItem);
        },
        clearEditedItem: function(){
            this.isModifying = false;
            this.editedItem = {
                eventName: '',
                cover: '',
                startDate: '',
                endDate: '',
                levelCnt: 0,
                levels:[],
                regions:["AA"],
            }
        },
        modEventMeta: function(eventItem){
            this.editedItem = Object.assign({}, eventItem);
            this.isModifying =true;
            this.eventDailog = true;
        },
        getIsFreeColor: function(isFree){
            return isFree?"green":"orange";
        },
        deleteLocalName: function(name){
            console.log(name);
            this.$delete(this.editedItem.localName, name);
        },
        getIsFreeContent: function(isFree){
            return isFree?"免费":"付费";
        },
        addEventDailog: function(){
            this.clearEditedItem();
            this.eventDailog = true;
        },

        modCollection: function(col){
            this.editedItem = col;
            this.isModifying = true;
            this.eventDailog = true;
            console.log(col);
        },

        delCollection: function(col){
            this.editedItem = col;
            this.isModifying = true;
            this.delCol();
            console.log(col);
        },
        fileuploadChange: function(files){
            console.log(files)
            var formData = new FormData();
            formData.append("image", files);
            formData.append("product", "fd")
            var vm = this;
            axios.post("/admin/api/uploadFile", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.status == 0){
                    vm.editedItem.cover = response.data.data.url;
                    //vm.editedItem.hash = response.data.data.hash;
                }else{
                    bus.$emit("showSnackMessage", "图片上传失败 " + response.data.msg, true)
                }
              
            }).catch(function(){
                bus.$emit("showSnackMessage", "图片上传失败 ", true)
                console.log("upload failed");

            })
        },

        realAddEvent: function(eventItem){
            this.uploading = true;
            var vm = this;
            eventItem.levelCnt = eventItem.levels.length;
            var formData = new FormData();
            for ( var key in eventItem ) {
                var item = eventItem[key];
                if (key == "levels"){
                    formData.append("levels", JSON.stringify(item));
                    continue;
                }
                if (Array.isArray(item)){
                    for(var index in item){
                        formData.append(key, item[index])
                    }
                }else if (typeof item === "object" && item != null){
                    formData.append(key, JSON.stringify(item))
                }else{
                    formData.append(key, item)
                }
                
            }
            axios.post('/admin/api/fd/addEvent', formData, {
                headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(response){              
                console.log(response);
                vm.clearEditedItem();
                vm.isModifying = false;
                vm.eventDailog = false;
                vm.uploading = false;
                bus.$emit("showSnackMessage", "新增成功")
                vm.reloadData();
            }).catch(function(error){
                console.log(error);
                vm.uploading = false;
                bus.$emit("showSnackMessage", "新增失败" + error, true)
                vm.reloadData();
            });
        },

        modEvent: function(eventItem){
            this.realAddEvent(eventItem);
            // var vm = this;
            // var formData = new FormData();
            //  for ( var key in this.editedItem ) {
            //     var item = this.editedItem[key];
            //     if (key == "levels"){
            //         formData.append("levels", JSON.stringify(item));
            //         continue;
            //     }
            //     if (Array.isArray(item)){
            //         for(var index in item){
            //             formData.append(key, item[index])
            //         }
            //     }else if (typeof item === "object" && item != null){
            //         formData.append(key, JSON.stringify(item))
            //     }else{
            //         formData.append(key, item)
            //     }
                
            // }
            // console.log(this.editedItem);
            // axios.post('/admin/api/fd/modEvent', formData, {
            //     headers: {
            //             'Content-Type': 'application/x-www-form-urlencoded'
            //     }
            // }).then(function(response){              
            //     console.log(response);
            //     vm.collectionDailog = false;
            //     vm.isModifying = false;
            //     vm.clearEditedItem();   
            //     bus.$emit("showSnackMessage", "修改成功")
            //     vm.reloadData();
            // }).catch(function(error){
            //     console.log(error);
            //     bus.$emit("showSnackMessage", "修改失败 "+error, true);
            //     vm.reloadData();
            // });
        },

        delEvent: function(eventItem){
            var vm = this;
            this.$confirm("确定要删除?").then(res => {
                if(res === true){
                    var form = new FormData();
                    form.append("id", eventItem.id);
                    axios.post("/admin/api/fd/delEvent", form, {
                        headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).then(function(response){
                        if(response.data.status == 0){
                             bus.$emit("删除成功");
                             vm.clearEditedItem();
                             vm.isModifying = false;
                             vm.reloadData();
                        }else{
                            bus.$emit("showSnackMessage", "删除错误: " + response.data.msg, true);                      
                        }
                    }).catch(function(err){
                        bus.$emit("showSnackMessage", "错误发生: " + err, true);
                    })
                }

            })
        },
        reloadData: function(){
            console.log("will reload data");
            var vm = this;
            axios.get("/admin/api/fd/listEvent").then(function(response){
                if(response.data.status == 0){
                    console.log(response);
                    vm.tabdata = response.data.data;
                }else{
                    bus.$emit("showSnackMessage", "刷新collection数据失败" + response.data.msg, true);
                }
            }).catch(function(err){
                bus.$emit("showSnackMessage", "刷新collection数据失败，需要异常 ：" +err);
            })
        },
        addEvent: function(){
            this.realAddEvent(this.editedItem);
            // var vm = this;
            // var formData = new FormData();
            // for ( var key in this.editedItem ) {
            //     var item = this.editedItem[key];
            //     if (key == "levels"){
            //         formData.append("levels", JSON.stringify(item));
            //         continue;
            //     }
            //     if (Array.isArray(item)){
            //         for(var index in item){
            //             formData.append(key, item[index])
            //         }
            //     }else if (typeof item === "object" && item != null){
            //         formData.append(key, JSON.stringify(item))
            //     }else{
            //         formData.append(key, item)
            //     }
                
            // }
            // axios.post('/admin/api/fd/addEvent', formData, {
            //     headers: {
            //             'Content-Type': 'application/x-www-form-urlencoded'
            //     }
            // }).then(function(response){              
            //     console.log(response);
            //     vm.clearEditedItem();
            //     vm.isModifying = false;
            //     vm.collectionDailog = false;
            //     bus.$emit("showSnackMessage", "新增成功")
            //     vm.reloadData();
            // }).catch(function(error){
            //     console.log(error);
            //     bus.$emit("showSnackMessage", "新增失败" + error, true)
            //     vm.reloadData();
            // });

        },
        DailogSave: function(){
            this.addEvent();
            // if(!this.isModifying){
            //     this.addEvent();
            // }else{
            //     this.modCol();
            // }
            
        },
        DailogCancel: function(){
            this.eventDailog = false;
            this.clearEditedItem();
        },
        isFreeChange: function(){
            this.editedItem.coins = 0;
            this.editedItem.price = 0;
            this.editedItem.IAPId = "";
            
        }

    },

    mounted: function(){
        this.reloadData();
    },
})
</script>

<style >
.v-tab {
text-transform: none !important;
}
</style>
