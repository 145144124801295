<template>
    <v-container>
        <v-card class="ma-3">
            <v-tabs v-model="tab" grow>
                <v-tab>关卡配置</v-tab>
                <v-tab>事件配置</v-tab>
                <v-tab>每日任务</v-tab>
            </v-tabs>
            <v-card class="ma-3">
                <v-tabs-items v-model="tab" class="mt-3" flat>
                    <v-tab-item>
                        <fd-level-config/>
                    </v-tab-item>
                     <v-tab-item>
                         <fd-event-config/>
                    </v-tab-item>
                     <v-tab-item>
                        <fd-dailytask-config/>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-card>
    </v-container>
</template>
<script>
import FdLevelConfig from '@/components/fd/LevelConfig.vue'
import FdEventConfig from '@/components/fd/EventConfig.vue'
import FdDailyTaskConfig from '@/components/fd/DailyTaskConfig.vue'
export default {
    components: {
        'fd-level-config': FdLevelConfig,
        'fd-event-config': FdEventConfig,
        'fd-dailytask-config': FdDailyTaskConfig,
    },
    name: "FindDifference",
    data: ()=>{
        return {
            tab: null,
        }
    }
}
</script>
