<template>
    <v-app>
      <navbar>
      </navbar>
      <v-content>
        <router-view/>
      </v-content>
    </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
  import Navbar from '@/components/Navbar.vue'
  import {bus} from '@/main'
  import axios from 'axios'

  export default {
    name: "App",
    components:{
      'navbar': Navbar,
    },
    data: function(){
      return {

      }
    },
    created: function(){
      var vm = this;
      axios.get("/admin/getuserinfo").then(function(response){
        if(response.data.status === 0){
          bus.$emit("userInfoChange", response.data.data, true);
        }else{
          vm.$router.push("/login");
        }
      })
    },
  }
</script>




