var RegionUtils = {
    getNameByCode: function(code){
        var name = "ERROR";
        //console.log("get name by code :" + this.regions)
        for(var i=0;i<this.regions.length;i++){
            if(this.regions[i]['alpha2'] === code){
                name = this.regions[i]['name'];
            }
        }
        return name;
        
    },

    getCNNameByCode:function(code){
        var name="错误";
        for(var i=0;i<this.regions.length;i++){
            if(this.regions[i]['alpha2'] === code){
                name = this.regions[i]['nameCN'];
            }
        }
        return name;
    },

    getCodeByName: function(name){
        var code = "ERROR";
        for(var i=0;i<this.regions.length;i++){
            if(this.regions[i]['name'] === name){
                code = this.regions[i]['alpha2'];
            }
        }
        return code;
    },

    getLangNameByCode: function(code){
        var name="错误";
        for(var i=0;i<this.lang.length;i++){
            if(this.lang[i]['code'] === code){
                name = this.lang[i]['name'];
            }
        }
        return name;
    },

    lang: [
        {
            "code": "zh-cn",
            "name": "简体中文",
        },
        {
            "code": "zh-tw",
            "name": "繁体中文",
        },
        {
            "code": "ja",
            "name": "日语",
        },
        {
            "code": "pt",
            "name": "葡萄牙语",
        },
        {
            "code": "es",
            "name": "西班牙语",
        },
        {
            "code": "ko",
            "name": "韩语",
        },
        {
            "code": "ru",
            "name": "俄语",
        },
        {
            "code": "fr",
            "name": "法语",
        },
        {
            "code": "de",
            "name": "德语",
        },
        {
            "code": "tr",
            "name": "土耳其语",
        },
        {
            "code": "ar",
            "name": "阿拉伯语",
        },
        {
            "code": "hi",
            "name": "印地语",
        },
        {
            "code": "ms",
            "name": "马来语",
        },
        {
            "code": "th",
            "name": "泰语",
        },

    ],

    regions:[
                {
                    "alpha2": "AA",
                    "nameCN": "全球",
                    "name": "Global",
                    "alpha3": "AAA",
                    "code": "0"
                },
                {
                    "alpha2": "AF",
                    "nameCN": "阿富汗",
                    "name": "Afghanistan",
                    "alpha3": "AFG",
                    "code": "4"
                },
                {
                    "alpha2": "AX",
                    "nameCN": "奥兰群岛",
                    "name": "Aland Islands",
                    "alpha3": "ALA",
                    "code": "248"
                },
                {
                    "alpha2": "AL",
                    "nameCN": "阿尔巴尼亚",
                    "name": "Albania",
                    "alpha3": "ALB",
                    "code": "8"
                },
                {
                    "alpha2": "DZ",
                    "nameCN": "阿尔及利亚",
                    "name": "Algeria",
                    "alpha3": "DZA",
                    "code": "12"
                },
                {
                    "alpha2": "AS",
                    "nameCN": "美属萨摩亚",
                    "name": "American Samoa",
                    "alpha3": "ASM",
                    "code": "16"
                },
                {
                    "alpha2": "AD",
                    "nameCN": "安道尔",
                    "name": "Andorra",
                    "alpha3": "AND",
                    "code": "20"
                },
                {
                    "alpha2": "AO",
                    "nameCN": "安哥拉",
                    "name": "Angola",
                    "alpha3": "AGO",
                    "code": "24"
                },
                {
                    "alpha2": "AI",
                    "nameCN": "安圭拉",
                    "name": "Anguilla",
                    "alpha3": "AIA",
                    "code": "660"
                },
                {
                    "alpha2": "AQ",
                    "nameCN": "南极洲",
                    "name": "Antarctica",
                    "alpha3": "ATA",
                    "code": "10"
                },
                {
                    "alpha2": "AG",
                    "nameCN": "安提瓜和巴布达",
                    "name": "Antigua and Barbuda",
                    "alpha3": "ATG",
                    "code": "28"
                },
                {
                    "alpha2": "AR",
                    "nameCN": "阿根廷",
                    "name": "Argentina",
                    "alpha3": "ARG",
                    "code": "32"
                },
                {
                    "alpha2": "AM",
                    "nameCN": "亚美尼亚",
                    "name": "Armenia",
                    "alpha3": "ARM",
                    "code": "51"
                },
                {
                    "alpha2": "AW",
                    "nameCN": "阿鲁巴",
                    "name": "Aruba",
                    "alpha3": "ABW",
                    "code": "533"
                },
                {
                    "alpha2": "AU",
                    "nameCN": "澳大利亚",
                    "name": "Australia",
                    "alpha3": "AUS",
                    "code": "36"
                },
                {
                    "alpha2": "AT",
                    "nameCN": "奥地利",
                    "name": "Austria",
                    "alpha3": "AUT",
                    "code": "40"
                },
                {
                    "alpha2": "AZ",
                    "nameCN": "阿塞拜疆",
                    "name": "Azerbaijan",
                    "alpha3": "AZE",
                    "code": "31"
                },
                {
                    "alpha2": "BS",
                    "nameCN": "巴哈马",
                    "name": "Bahamas",
                    "alpha3": "BHS",
                    "code": "44"
                },
                {
                    "alpha2": "BH",
                    "nameCN": "巴林",
                    "name": "Bahrain",
                    "alpha3": "BHR",
                    "code": "48"
                },
                {
                    "alpha2": "BD",
                    "nameCN": "孟加拉国",
                    "name": "Bangladesh",
                    "alpha3": "BGD",
                    "code": "50"
                },
                {
                    "alpha2": "BB",
                    "nameCN": "巴巴多斯",
                    "name": "Barbados",
                    "alpha3": "BRB",
                    "code": "52"
                },
                {
                    "alpha2": "BY",
                    "nameCN": "白俄罗斯",
                    "name": "Belarus",
                    "alpha3": "BLR",
                    "code": "112"
                },
                {
                    "alpha2": "BE",
                    "nameCN": "比利时",
                    "name": "Belgium",
                    "alpha3": "BEL",
                    "code": "56"
                },
                {
                    "alpha2": "BZ",
                    "nameCN": "伯利兹",
                    "name": "Belize",
                    "alpha3": "BLZ",
                    "code": "84"
                },
                {
                    "alpha2": "BJ",
                    "nameCN": "贝宁",
                    "name": "Benin",
                    "alpha3": "BEN",
                    "code": "204"
                },
                {
                    "alpha2": "BM",
                    "nameCN": "百慕大",
                    "name": "Bermuda",
                    "alpha3": "BMU",
                    "code": "60"
                },
                {
                    "alpha2": "BT",
                    "nameCN": "不丹",
                    "name": "Bhutan",
                    "alpha3": "BTN",
                    "code": "64"
                },
                {
                    "alpha2": "BO",
                    "nameCN": "玻利维亚",
                    "name": "Bolivia",
                    "alpha3": "BOL",
                    "code": "68"
                },
                {
                    "alpha2": "BA",
                    "nameCN": "波黑",
                    "name": "Bosnia and Herzegovina",
                    "alpha3": "BIH",
                    "code": "70"
                },
                {
                    "alpha2": "BW",
                    "nameCN": "博茨瓦纳",
                    "name": "Botswana",
                    "alpha3": "BWA",
                    "code": "72"
                },
                {
                    "alpha2": "BV",
                    "nameCN": "布维岛",
                    "name": "Bouvet Island",
                    "alpha3": "BVT",
                    "code": "74"
                },
                {
                    "alpha2": "BR",
                    "nameCN": "巴西",
                    "name": "Brazil",
                    "alpha3": "BRA",
                    "code": "76"
                },
                {
                    "alpha2": "IO",
                    "nameCN": "英属印度洋领地",
                    "name": "British Indian Ocean Territory",
                    "alpha3": "IOT",
                    "code": "86"
                },
                {
                    "alpha2": "BN",
                    "nameCN": "文莱",
                    "name": "Brunei Darussalam",
                    "alpha3": "BRN",
                    "code": "96"
                },
                {
                    "alpha2": "BG",
                    "nameCN": "保加利亚",
                    "name": "Bulgaria",
                    "alpha3": "BGR",
                    "code": "100"
                },
                {
                    "alpha2": "BF",
                    "nameCN": "布基纳法索",
                    "name": "Burkina Faso",
                    "alpha3": "BFA",
                    "code": "854"
                },
                {
                    "alpha2": "BI",
                    "nameCN": "布隆迪",
                    "name": "Burundi",
                    "alpha3": "BDI",
                    "code": "108"
                },
                {
                    "alpha2": "KH",
                    "nameCN": "柬埔寨",
                    "name": "Cambodia",
                    "alpha3": "KHM",
                    "code": "116"
                },
                {
                    "alpha2": "CM",
                    "nameCN": "喀麦隆",
                    "name": "Cameroon",
                    "alpha3": "CMR",
                    "code": "120"
                },
                {
                    "alpha2": "CA",
                    "nameCN": "加拿大",
                    "name": "Canada",
                    "alpha3": "CAN",
                    "code": "124"
                },
                {
                    "alpha2": "CV",
                    "nameCN": "佛得角",
                    "name": "Cape Verde",
                    "alpha3": "CPV",
                    "code": "132"
                },
                {
                    "alpha2": "KY",
                    "nameCN": "开曼群岛",
                    "name": "Cayman Islands",
                    "alpha3": "CYM",
                    "code": "136"
                },
                {
                    "alpha2": "CF",
                    "nameCN": "中非",
                    "name": "Central African Republic",
                    "alpha3": "CAF",
                    "code": "140"
                },
                {
                    "alpha2": "TD",
                    "nameCN": "乍得",
                    "name": "Chad",
                    "alpha3": "TCD",
                    "code": "148"
                },
                {
                    "alpha2": "CL",
                    "nameCN": "智利",
                    "name": "Chile",
                    "alpha3": "CHL",
                    "code": "152"
                },
                {
                    "alpha2": "CN",
                    "nameCN": "中国",
                    "name": "China",
                    "alpha3": "CHN",
                    "code": "156"
                },
                {
                    "alpha2": "CX",
                    "nameCN": "圣诞岛",
                    "name": "Christmas Island",
                    "alpha3": "CXR",
                    "code": "162"
                },
                {
                    "alpha2": "CC",
                    "nameCN": "科科斯群岛",
                    "name": "Cocos Islands",
                    "alpha3": "CCK",
                    "code": "166"
                },
                {
                    "alpha2": "CO",
                    "nameCN": "哥伦比亚",
                    "name": "Colombia",
                    "alpha3": "COL",
                    "code": "170"
                },
                {
                    "alpha2": "KM",
                    "nameCN": "科摩罗",
                    "name": "Comoros",
                    "alpha3": "COM",
                    "code": "174"
                },
                {
                    "alpha2": "CG",
                    "nameCN": "刚果（布）",
                    "name": "Congo",
                    "alpha3": "COG",
                    "code": "178"
                },
                {
                    "alpha2": "CD",
                    "nameCN": "刚果（金）",
                    "name": "Congo(DR)",
                    "alpha3": "COD",
                    "code": "180"
                },
                {
                    "alpha2": "CK",
                    "nameCN": "库克群岛",
                    "name": "Cook Islands",
                    "alpha3": "COK",
                    "code": "184"
                },
                {
                    "alpha2": "CR",
                    "nameCN": "哥斯达黎加",
                    "name": "Costa Rica",
                    "alpha3": "CRI",
                    "code": "188"
                },
                {
                    "alpha2": "CI",
                    "nameCN": "科特迪瓦",
                    "name": "Côte d'Ivoire",
                    "alpha3": "CIV",
                    "code": "384"
                },
                {
                    "alpha2": "HR",
                    "nameCN": "克罗地亚",
                    "name": "Croatia",
                    "alpha3": "HRV",
                    "code": "191"
                },
                {
                    "alpha2": "CU",
                    "nameCN": "古巴",
                    "name": "Cuba",
                    "alpha3": "CUB",
                    "code": "192"
                },
                {
                    "alpha2": "CY",
                    "nameCN": "塞浦路斯",
                    "name": "Cyprus",
                    "alpha3": "CYP",
                    "code": "196"
                },
                {
                    "alpha2": "CZ",
                    "nameCN": "捷克",
                    "name": "Czech Republic",
                    "alpha3": "CZE",
                    "code": "203"
                },
                {
                    "alpha2": "DK",
                    "nameCN": "丹麦",
                    "name": "Denmark",
                    "alpha3": "DNK",
                    "code": "208"
                },
                {
                    "alpha2": "DJ",
                    "nameCN": "吉布提",
                    "name": "Djibouti",
                    "alpha3": "DJI",
                    "code": "262"
                },
                {
                    "alpha2": "DM",
                    "nameCN": "多米尼克",
                    "name": "Dominica",
                    "alpha3": "DMA",
                    "code": "212"
                },
                {
                    "alpha2": "DO",
                    "nameCN": "多米尼加",
                    "name": "Dominican Republic",
                    "alpha3": "DOM",
                    "code": "214"
                },
                {
                    "alpha2": "EC",
                    "nameCN": "厄瓜多尔",
                    "name": "Ecuador",
                    "alpha3": "ECU",
                    "code": "218"
                },
                {
                    "alpha2": "EG",
                    "nameCN": "埃及",
                    "name": "Egypt",
                    "alpha3": "EGY",
                    "code": "818"
                },
                {
                    "alpha2": "SV",
                    "nameCN": "萨尔瓦多",
                    "name": "El Salvador",
                    "alpha3": "SLV",
                    "code": "222"
                },
                {
                    "alpha2": "GQ",
                    "nameCN": "赤道几内亚",
                    "name": "Equatorial Guinea",
                    "alpha3": "GNQ",
                    "code": "226"
                },
                {
                    "alpha2": "ER",
                    "nameCN": "厄立特里亚",
                    "name": "Eritrea",
                    "alpha3": "ERI",
                    "code": "232"
                },
                {
                    "alpha2": "EE",
                    "nameCN": "爱沙尼亚",
                    "name": "Estonia",
                    "alpha3": "EST",
                    "code": "233"
                },
                {
                    "alpha2": "ET",
                    "nameCN": "埃塞俄比亚",
                    "name": "Ethiopia",
                    "alpha3": "ETH",
                    "code": "231"
                },
                {
                    "alpha2": "FK",
                    "nameCN": "马尔维纳斯群岛",
                    "name": "Malvinas Islands",
                    "alpha3": "FLK",
                    "code": "238"
                },
                {
                    "alpha2": "FO",
                    "nameCN": "法罗群岛",
                    "name": "Faroe Islands",
                    "alpha3": "FRO",
                    "code": "234"
                },
                {
                    "alpha2": "FJ",
                    "nameCN": "斐济",
                    "name": "Fiji",
                    "alpha3": "FJI",
                    "code": "242"
                },
                {
                    "alpha2": "FI",
                    "nameCN": "芬兰",
                    "name": "Finland",
                    "alpha3": "FIN",
                    "code": "246"
                },
                {
                    "alpha2": "FR",
                    "nameCN": "法国",
                    "name": "France",
                    "alpha3": "FRA",
                    "code": "250"
                },
                {
                    "alpha2": "GF",
                    "nameCN": "法属圭亚那",
                    "name": "French Guiana",
                    "alpha3": "GUF",
                    "code": "254"
                },
                {
                    "alpha2": "PF",
                    "nameCN": "法属波利尼西亚",
                    "name": "French Polynesia",
                    "alpha3": "PYF",
                    "code": "258"
                },
                {
                    "alpha2": "TF",
                    "nameCN": "法属南部领地",
                    "name": "French Southern Territories",
                    "alpha3": "ATF",
                    "code": "260"
                },
                {
                    "alpha2": "GA",
                    "nameCN": "加蓬",
                    "name": "Gabon",
                    "alpha3": "GAB",
                    "code": "266"
                },
                {
                    "alpha2": "GM",
                    "nameCN": "冈比亚",
                    "name": "Gambia",
                    "alpha3": "GMB",
                    "code": "270"
                },
                {
                    "alpha2": "GE",
                    "nameCN": "格鲁吉亚",
                    "name": "Georgia",
                    "alpha3": "GEO",
                    "code": "268"
                },
                {
                    "alpha2": "DE",
                    "nameCN": "德国",
                    "name": "Germany",
                    "alpha3": "DEU",
                    "code": "276"
                },
                {
                    "alpha2": "GH",
                    "nameCN": "加纳",
                    "name": "Ghana",
                    "alpha3": "GHA",
                    "code": "288"
                },
                {
                    "alpha2": "GI",
                    "nameCN": "直布罗陀",
                    "name": "Gibraltar",
                    "alpha3": "GIB",
                    "code": "292"
                },
                {
                    "alpha2": "GR",
                    "nameCN": "希腊",
                    "name": "Greece",
                    "alpha3": "GRC",
                    "code": "300"
                },
                {
                    "alpha2": "GL",
                    "nameCN": "格陵兰",
                    "name": "Greenland",
                    "alpha3": "GRL",
                    "code": "304"
                },
                {
                    "alpha2": "GD",
                    "nameCN": "格林纳达",
                    "name": "Grenada",
                    "alpha3": "GRD",
                    "code": "308"
                },
                {
                    "alpha2": "GP",
                    "nameCN": "瓜德罗普",
                    "name": "Guadeloupe",
                    "alpha3": "GLP",
                    "code": "312"
                },
                {
                    "alpha2": "GU",
                    "nameCN": "关岛",
                    "name": "Guam",
                    "alpha3": "GUM",
                    "code": "316"
                },
                {
                    "alpha2": "GT",
                    "nameCN": "危地马拉",
                    "name": "Guatemala",
                    "alpha3": "GTM",
                    "code": "320"
                },
                {
                    "alpha2": "GG",
                    "nameCN": "格恩西岛",
                    "name": "Guernsey",
                    "alpha3": "GGY",
                    "code": "831"
                },
                {
                    "alpha2": "GN",
                    "nameCN": "几内亚",
                    "name": "Guinea",
                    "alpha3": "GIN",
                    "code": "324"
                },
                {
                    "alpha2": "GW",
                    "nameCN": "几内亚比绍",
                    "name": "Guinea-Bissau",
                    "alpha3": "GNB",
                    "code": "624"
                },
                {
                    "alpha2": "GY",
                    "nameCN": "圭亚那",
                    "name": "Guyana",
                    "alpha3": "GUY",
                    "code": "328"
                },
                {
                    "alpha2": "HT",
                    "nameCN": "海地",
                    "name": "Haiti",
                    "alpha3": "HTI",
                    "code": "332"
                },
                {
                    "alpha2": "HM",
                    "nameCN": "赫德岛和麦克唐纳岛",
                    "name": "Heard Island",
                    "alpha3": "HMD",
                    "code": "334"
                },
                {
                    "alpha2": "VA",
                    "nameCN": "梵蒂冈",
                    "name": "Vatican City",
                    "alpha3": "VAT",
                    "code": "336"
                },
                {
                    "alpha2": "HN",
                    "nameCN": "洪都拉斯",
                    "name": "Honduras",
                    "alpha3": "HND",
                    "code": "340"
                },
                {
                    "alpha2": "HK",
                    "nameCN": "香港",
                    "name": "Hong Kong",
                    "alpha3": "HKG",
                    "code": "344"
                },
                {
                    "alpha2": "HU",
                    "nameCN": "匈牙利",
                    "name": "Hungary",
                    "alpha3": "HUN",
                    "code": "348"
                },
                {
                    "alpha2": "IS",
                    "nameCN": "冰岛",
                    "name": "Iceland",
                    "alpha3": "ISL",
                    "code": "352"
                },
                {
                    "alpha2": "IN",
                    "nameCN": "印度",
                    "name": "India",
                    "alpha3": "IND",
                    "code": "356"
                },
                {
                    "alpha2": "ID",
                    "nameCN": "印度尼西亚",
                    "name": "Indonesia",
                    "alpha3": "IDN",
                    "code": "360"
                },
                {
                    "alpha2": "IR",
                    "nameCN": "伊朗",
                    "name": "Iran",
                    "alpha3": "IRN",
                    "code": "364"
                },
                {
                    "alpha2": "IQ",
                    "nameCN": "伊拉克",
                    "name": "Iraq",
                    "alpha3": "IRQ",
                    "code": "368"
                },
                {
                    "alpha2": "IE",
                    "nameCN": "爱尔兰",
                    "name": "Ireland",
                    "alpha3": "IRL",
                    "code": "372"
                },
                {
                    "alpha2": "IM",
                    "nameCN": "英国属地曼岛",
                    "name": "Isle of Man",
                    "alpha3": "IMN",
                    "code": "833"
                },
                {
                    "alpha2": "IL",
                    "nameCN": "以色列",
                    "name": "Israel",
                    "alpha3": "ISR",
                    "code": "376"
                },
                {
                    "alpha2": "IT",
                    "nameCN": "意大利",
                    "name": "Italy",
                    "alpha3": "ITA",
                    "code": "380"
                },
                {
                    "alpha2": "JM",
                    "nameCN": "牙买加",
                    "name": "Jamaica",
                    "alpha3": "JAM",
                    "code": "388"
                },
                {
                    "alpha2": "JP",
                    "nameCN": "日本",
                    "name": "Japan",
                    "alpha3": "JPN",
                    "code": "392"
                },
                {
                    "alpha2": "JE",
                    "nameCN": "泽西岛",
                    "name": "Jersey",
                    "alpha3": "JEY",
                    "code": "832"
                },
                {
                    "alpha2": "JO",
                    "nameCN": "约旦",
                    "name": "Jordan",
                    "alpha3": "JOR",
                    "code": "400"
                },
                {
                    "alpha2": "KZ",
                    "nameCN": "哈萨克斯坦",
                    "name": "Kazakhstan",
                    "alpha3": "KAZ",
                    "code": "398"
                },
                {
                    "alpha2": "KE",
                    "nameCN": "肯尼亚",
                    "name": "Kenya",
                    "alpha3": "KEN",
                    "code": "404"
                },
                {
                    "alpha2": "KI",
                    "nameCN": "基里巴斯",
                    "name": "Kiribati",
                    "alpha3": "KIR",
                    "code": "296"
                },
                {
                    "alpha2": "KP",
                    "nameCN": "朝鲜",
                    "name": "North Korea",
                    "alpha3": "PRK",
                    "code": "408"
                },
                {
                    "alpha2": "KR",
                    "nameCN": "韩国",
                    "name": "South Korea",
                    "alpha3": "KOR",
                    "code": "410"
                },
                {
                    "alpha2": "KW",
                    "nameCN": "科威特",
                    "name": "Kuwait",
                    "alpha3": "KWT",
                    "code": "414"
                },
                {
                    "alpha2": "KG",
                    "nameCN": "吉尔吉斯斯坦",
                    "name": "Kyrgyzstan",
                    "alpha3": "KGZ",
                    "code": "417"
                },
                {
                    "alpha2": "LA",
                    "nameCN": "老挝",
                    "name": "Laos",
                    "alpha3": "LAO",
                    "code": "418"
                },
                {
                    "alpha2": "LV",
                    "nameCN": "拉脱维亚",
                    "name": "Latvia",
                    "alpha3": "LVA",
                    "code": "428"
                },
                {
                    "alpha2": "LB",
                    "nameCN": "黎巴嫩",
                    "name": "Lebanon",
                    "alpha3": "LBN",
                    "code": "422"
                },
                {
                    "alpha2": "LS",
                    "nameCN": "莱索托",
                    "name": "Lesotho",
                    "alpha3": "LSO",
                    "code": "426"
                },
                {
                    "alpha2": "LR",
                    "nameCN": "利比里亚",
                    "name": "Liberia",
                    "alpha3": "LBR",
                    "code": "430"
                },
                {
                    "alpha2": "LY",
                    "nameCN": "利比亚",
                    "name": "Libyan",
                    "alpha3": "LBY",
                    "code": "434"
                },
                {
                    "alpha2": "LI",
                    "nameCN": "列支敦士登",
                    "name": "Liechtenstein",
                    "alpha3": "LIE",
                    "code": "438"
                },
                {
                    "alpha2": "LT",
                    "nameCN": "立陶宛",
                    "name": "Lithuania",
                    "alpha3": "LTU",
                    "code": "440"
                },
                {
                    "alpha2": "LU",
                    "nameCN": "卢森堡",
                    "name": "Luxembourg",
                    "alpha3": "LUX",
                    "code": "442"
                },
                {
                    "alpha2": "MO",
                    "nameCN": "澳门",
                    "name": "Macao",
                    "alpha3": "MAC",
                    "code": "446"
                },
                {
                    "alpha2": "MK",
                    "nameCN": "前南马其顿",
                    "name": "Macedonia",
                    "alpha3": "MKD",
                    "code": "807"
                },
                {
                    "alpha2": "MG",
                    "nameCN": "马达加斯加",
                    "name": "Madagascar",
                    "alpha3": "MDG",
                    "code": "450"
                },
                {
                    "alpha2": "MW",
                    "nameCN": "马拉维",
                    "name": "Malawi",
                    "alpha3": "MWI",
                    "code": "454"
                },
                {
                    "alpha2": "MY",
                    "nameCN": "马来西亚",
                    "name": "Malaysia",
                    "alpha3": "MYS",
                    "code": "458"
                },
                {
                    "alpha2": "MV",
                    "nameCN": "马尔代夫",
                    "name": "Maldives",
                    "alpha3": "MDV",
                    "code": "462"
                },
                {
                    "alpha2": "ML",
                    "nameCN": "马里",
                    "name": "Mali",
                    "alpha3": "MLI",
                    "code": "466"
                },
                {
                    "alpha2": "MT",
                    "nameCN": "马耳他",
                    "name": "Malta",
                    "alpha3": "MLT",
                    "code": "470"
                },
                {
                    "alpha2": "MH",
                    "nameCN": "马绍尔群岛",
                    "name": "Marshall Islands",
                    "alpha3": "MHL",
                    "code": "584"
                },
                {
                    "alpha2": "MQ",
                    "nameCN": "马提尼克",
                    "name": "Martinique",
                    "alpha3": "MTQ",
                    "code": "474"
                },
                {
                    "alpha2": "MR",
                    "nameCN": "毛利塔尼亚",
                    "name": "Mauritania",
                    "alpha3": "MRT",
                    "code": "478"
                },
                {
                    "alpha2": "MU",
                    "nameCN": "毛里求斯",
                    "name": "Mauritius",
                    "alpha3": "MUS",
                    "code": "480"
                },
                {
                    "alpha2": "YT",
                    "nameCN": "马约特",
                    "name": "Mayotte",
                    "alpha3": "MYT",
                    "code": "175"
                },
                {
                    "alpha2": "MX",
                    "nameCN": "墨西哥",
                    "name": "Mexico",
                    "alpha3": "MEX",
                    "code": "484"
                },
                {
                    "alpha2": "FM",
                    "nameCN": "密克罗尼西亚联邦",
                    "name": "Micronesia",
                    "alpha3": "FSM",
                    "code": "583"
                },
                {
                    "alpha2": "MD",
                    "nameCN": "摩尔多瓦",
                    "name": "Moldova",
                    "alpha3": "MDA",
                    "code": "498"
                },
                {
                    "alpha2": "MC",
                    "nameCN": "摩纳哥",
                    "name": "Monaco",
                    "alpha3": "MCO",
                    "code": "492"
                },
                {
                    "alpha2": "MN",
                    "nameCN": "蒙古",
                    "name": "Mongolia",
                    "alpha3": "MNG",
                    "code": "496"
                },
                {
                    "alpha2": "ME",
                    "nameCN": "黑山",
                    "name": "Montenegro",
                    "alpha3": "MNE",
                    "code": "499"
                },
                {
                    "alpha2": "MS",
                    "nameCN": "蒙特塞拉特",
                    "name": "Montserrat",
                    "alpha3": "MSR",
                    "code": "500"
                },
                {
                    "alpha2": "MA",
                    "nameCN": "摩洛哥",
                    "name": "Morocco",
                    "alpha3": "MAR",
                    "code": "504"
                },
                {
                    "alpha2": "MZ",
                    "nameCN": "莫桑比克",
                    "name": "Mozambique",
                    "alpha3": "MOZ",
                    "code": "508"
                },
                {
                    "alpha2": "MM",
                    "nameCN": "缅甸",
                    "name": "Myanmar",
                    "alpha3": "MMR",
                    "code": "104"
                },
                {
                    "alpha2": "NA",
                    "nameCN": "纳米比亚",
                    "name": "Namibia",
                    "alpha3": "NAM",
                    "code": "516"
                },
                {
                    "alpha2": "NR",
                    "nameCN": "瑙鲁",
                    "name": "Nauru",
                    "alpha3": "NRU",
                    "code": "520"
                },
                {
                    "alpha2": "NP",
                    "nameCN": "尼泊尔",
                    "name": "Nepal",
                    "alpha3": "NPL",
                    "code": "524"
                },
                {
                    "alpha2": "NL",
                    "nameCN": "荷兰",
                    "name": "Netherlands",
                    "alpha3": "NLD",
                    "code": "528"
                },
                {
                    "alpha2": "AN",
                    "nameCN": "荷属安的列斯",
                    "name": "Netherlands Antilles",
                    "alpha3": "ANT",
                    "code": "530"
                },
                {
                    "alpha2": "NC",
                    "nameCN": "新喀里多尼亚",
                    "name": "New Caledonia",
                    "alpha3": "NCL",
                    "code": "540"
                },
                {
                    "alpha2": "NZ",
                    "nameCN": "新西兰",
                    "name": "New Zealand",
                    "alpha3": "NZL",
                    "code": "554"
                },
                {
                    "alpha2": "NI",
                    "nameCN": "尼加拉瓜",
                    "name": "Nicaragua",
                    "alpha3": "NIC",
                    "code": "558"
                },
                {
                    "alpha2": "NE",
                    "nameCN": "尼日尔",
                    "name": "Niger",
                    "alpha3": "NER",
                    "code": "562"
                },
                {
                    "alpha2": "NG",
                    "nameCN": "尼日利亚",
                    "name": "Nigeria",
                    "alpha3": "NGA",
                    "code": "566"
                },
                {
                    "alpha2": "NU",
                    "nameCN": "纽埃",
                    "name": "Niue",
                    "alpha3": "NIU",
                    "code": "570"
                },
                {
                    "alpha2": "NF",
                    "nameCN": "诺福克岛",
                    "name": "Norfolk Island",
                    "alpha3": "NFK",
                    "code": "574"
                },
                {
                    "alpha2": "MP",
                    "nameCN": "北马里亚纳",
                    "name": "Northern Mariana Islands",
                    "alpha3": "MNP",
                    "code": "580"
                },
                {
                    "alpha2": "NO",
                    "nameCN": "挪威",
                    "name": "Norway",
                    "alpha3": "NOR",
                    "code": "578"
                },
                {
                    "alpha2": "OM",
                    "nameCN": "阿曼",
                    "name": "Oman",
                    "alpha3": "OMN",
                    "code": "512"
                },
                {
                    "alpha2": "PK",
                    "nameCN": "巴基斯坦",
                    "name": "Pakistan",
                    "alpha3": "PAK",
                    "code": "586"
                },
                {
                    "alpha2": "PW",
                    "nameCN": "帕劳",
                    "name": "Palau",
                    "alpha3": "PLW",
                    "code": "585"
                },
                {
                    "alpha2": "PS",
                    "nameCN": "巴勒斯坦",
                    "name": "Palestinian",
                    "alpha3": "PSE",
                    "code": "275"
                },
                {
                    "alpha2": "PA",
                    "nameCN": "巴拿马",
                    "name": "Panama",
                    "alpha3": "PAN",
                    "code": "591"
                },
                {
                    "alpha2": "PG",
                    "nameCN": "巴布亚新几内亚",
                    "name": "Papua New Guinea",
                    "alpha3": "PNG",
                    "code": "598"
                },
                {
                    "alpha2": "PY",
                    "nameCN": "巴拉圭",
                    "name": "Paraguay",
                    "alpha3": "PRY",
                    "code": "600"
                },
                {
                    "alpha2": "PE",
                    "nameCN": "秘鲁",
                    "name": "Peru",
                    "alpha3": "PER",
                    "code": "604"
                },
                {
                    "alpha2": "PH",
                    "nameCN": "菲律宾",
                    "name": "Philippines",
                    "alpha3": "PHL",
                    "code": "608"
                },
                {
                    "alpha2": "PN",
                    "nameCN": "皮特凯恩",
                    "name": "Pitcairn",
                    "alpha3": "PCN",
                    "code": "612"
                },
                {
                    "alpha2": "PL",
                    "nameCN": "波兰",
                    "name": "Poland",
                    "alpha3": "POL",
                    "code": "616"
                },
                {
                    "alpha2": "PT",
                    "nameCN": "葡萄牙",
                    "name": "Portugal",
                    "alpha3": "PRT",
                    "code": "620"
                },
                {
                    "alpha2": "PR",
                    "nameCN": "波多黎各",
                    "name": "Puerto Rico",
                    "alpha3": "PRI",
                    "code": "630"
                },
                {
                    "alpha2": "QA",
                    "nameCN": "卡塔尔",
                    "name": "Qatar",
                    "alpha3": "QAT",
                    "code": "634"
                },
                {
                    "alpha2": "RE",
                    "nameCN": "留尼汪",
                    "name": "Réunion",
                    "alpha3": "REU",
                    "code": "638"
                },
                {
                    "alpha2": "RO",
                    "nameCN": "罗马尼亚",
                    "name": "Romania",
                    "alpha3": "ROU",
                    "code": "642"
                },
                {
                    "alpha2": "RU",
                    "nameCN": "俄罗斯联邦",
                    "name": "Russian",
                    "alpha3": "RUS",
                    "code": "643"
                },
                {
                    "alpha2": "RW",
                    "nameCN": "卢旺达",
                    "name": "Rwanda",
                    "alpha3": "RWA",
                    "code": "646"
                },
                {
                    "alpha2": "SH",
                    "nameCN": "圣赫勒拿",
                    "name": "Saint Helena",
                    "alpha3": "SHN",
                    "code": "654"
                },
                {
                    "alpha2": "KN",
                    "nameCN": "圣基茨和尼维斯",
                    "name": "Saint Kitts",
                    "alpha3": "KNA",
                    "code": "659"
                },
                {
                    "alpha2": "LC",
                    "nameCN": "圣卢西亚",
                    "name": "Saint Lucia",
                    "alpha3": "LCA",
                    "code": "662"
                },
                {
                    "alpha2": "PM",
                    "nameCN": "圣皮埃尔和密克隆",
                    "name": "Saint Pierre",
                    "alpha3": "SPM",
                    "code": "666"
                },
                {
                    "alpha2": "VC",
                    "nameCN": "圣文森特和格林纳丁斯",
                    "name": "Saint Vincent",
                    "alpha3": "VCT",
                    "code": "670"
                },
                {
                    "alpha2": "WS",
                    "nameCN": "萨摩亚",
                    "name": "Samoa",
                    "alpha3": "WSM",
                    "code": "882"
                },
                {
                    "alpha2": "SM",
                    "nameCN": "圣马力诺",
                    "name": "San Marino",
                    "alpha3": "SMR",
                    "code": "674"
                },
                {
                    "alpha2": "ST",
                    "nameCN": "圣多美和普林西比",
                    "name": "Sao Tome and Principe",
                    "alpha3": "STP",
                    "code": "678"
                },
                {
                    "alpha2": "SA",
                    "nameCN": "沙特阿拉伯",
                    "name": "Saudi Arabia",
                    "alpha3": "SAU",
                    "code": "682"
                },
                {
                    "alpha2": "SN",
                    "nameCN": "塞内加尔",
                    "name": "Senegal",
                    "alpha3": "SEN",
                    "code": "686"
                },
                {
                    "alpha2": "RS",
                    "nameCN": "塞尔维亚",
                    "name": "Serbia",
                    "alpha3": "SRB",
                    "code": "688"
                },
                {
                    "alpha2": "SC",
                    "nameCN": "塞舌尔",
                    "name": "Seychelles",
                    "alpha3": "SYC",
                    "code": "690"
                },
                {
                    "alpha2": "SL",
                    "nameCN": "塞拉利昂",
                    "name": "Sierra Leone",
                    "alpha3": "SLE",
                    "code": "694"
                },
                {
                    "alpha2": "SG",
                    "nameCN": "新加坡",
                    "name": "Singapore",
                    "alpha3": "SGP",
                    "code": "702"
                },
                {
                    "alpha2": "SK",
                    "nameCN": "斯洛伐克",
                    "name": "Slovakia",
                    "alpha3": "SVK",
                    "code": "703"
                },
                {
                    "alpha2": "SI",
                    "nameCN": "斯洛文尼亚",
                    "name": "Slovenia",
                    "alpha3": "SVN",
                    "code": "705"
                },
                {
                    "alpha2": "SB",
                    "nameCN": "所罗门群岛",
                    "name": "Solomon Islands (the)",
                    "alpha3": "SLB",
                    "code": "90"
                },
                {
                    "alpha2": "SO",
                    "nameCN": "索马里",
                    "name": "Somalia",
                    "alpha3": "SOM",
                    "code": "706"
                },
                {
                    "alpha2": "ZA",
                    "nameCN": "南非",
                    "name": "South Africa",
                    "alpha3": "ZAF",
                    "code": "710"
                },
                {
                    "alpha2": "GS",
                    "nameCN": "南乔治亚岛和南桑德韦奇岛",
                    "name": "South Georgia",
                    "alpha3": "SGS",
                    "code": "239"
                },
                {
                    "alpha2": "ES",
                    "nameCN": "西班牙",
                    "name": "Spain",
                    "alpha3": "ESP",
                    "code": "724"
                },
                {
                    "alpha2": "LK",
                    "nameCN": "斯里兰卡",
                    "name": "Sri Lanka",
                    "alpha3": "LKA",
                    "code": "144"
                },
                {
                    "alpha2": "SD",
                    "nameCN": "苏丹",
                    "name": "Sudan",
                    "alpha3": "SDN",
                    "code": "736"
                },
                {
                    "alpha2": "SR",
                    "nameCN": "苏里南",
                    "name": "Suriname",
                    "alpha3": "SUR",
                    "code": "740"
                },
                {
                    "alpha2": "SJ",
                    "nameCN": "斯瓦尔巴岛和扬马延岛",
                    "name": "Svalbard",
                    "alpha3": "SJM",
                    "code": "744"
                },
                {
                    "alpha2": "SZ",
                    "nameCN": "斯威士兰",
                    "name": "Swaziland",
                    "alpha3": "SWZ",
                    "code": "748"
                },
                {
                    "alpha2": "SE",
                    "nameCN": "瑞典",
                    "name": "Sweden",
                    "alpha3": "SWE",
                    "code": "752"
                },
                {
                    "alpha2": "CH",
                    "nameCN": "瑞士",
                    "name": "Switzerland",
                    "alpha3": "CHE",
                    "code": "756"
                },
                {
                    "alpha2": "SY",
                    "nameCN": "叙利亚",
                    "name": "Syrian",
                    "alpha3": "SYR",
                    "code": "760"
                },
                {
                    "alpha2": "TW",
                    "nameCN": "台湾",
                    "name": "Taiwan",
                    "alpha3": "TWN",
                    "code": "158"
                },
                {
                    "alpha2": "TJ",
                    "nameCN": "塔吉克斯坦",
                    "name": "Tajikistan",
                    "alpha3": "TJK",
                    "code": "762"
                },
                {
                    "alpha2": "TZ",
                    "nameCN": "坦桑尼亚",
                    "name": "Tanzania",
                    "alpha3": "TZA",
                    "code": "834"
                },
                {
                    "alpha2": "TH",
                    "nameCN": "泰国",
                    "name": "Thailand",
                    "alpha3": "THA",
                    "code": "764"
                },
                {
                    "alpha2": "TL",
                    "nameCN": "东帝汶",
                    "name": "Timor-Leste",
                    "alpha3": "TLS",
                    "code": "626"
                },
                {
                    "alpha2": "TG",
                    "nameCN": "多哥",
                    "name": "Togo",
                    "alpha3": "TGO",
                    "code": "768"
                },
                {
                    "alpha2": "TK",
                    "nameCN": "托克劳",
                    "name": "Tokelau",
                    "alpha3": "TKL",
                    "code": "772"
                },
                {
                    "alpha2": "TO",
                    "nameCN": "汤加",
                    "name": "Tonga",
                    "alpha3": "TON",
                    "code": "776"
                },
                {
                    "alpha2": "TT",
                    "nameCN": "特立尼达和多巴哥",
                    "name": "Trinidad and Tobago",
                    "alpha3": "TTO",
                    "code": "780"
                },
                {
                    "alpha2": "TN",
                    "nameCN": "突尼斯",
                    "name": "Tunisia",
                    "alpha3": "TUN",
                    "code": "788"
                },
                {
                    "alpha2": "TR",
                    "nameCN": "土耳其",
                    "name": "Turkey",
                    "alpha3": "TUR",
                    "code": "792"
                },
                {
                    "alpha2": "TM",
                    "nameCN": "土库曼斯坦",
                    "name": "Turkmenistan",
                    "alpha3": "TKM",
                    "code": "795"
                },
                {
                    "alpha2": "TC",
                    "nameCN": "特克斯和凯科斯群岛",
                    "name": "Turks",
                    "alpha3": "TCA",
                    "code": "796"
                },
                {
                    "alpha2": "TV",
                    "nameCN": "图瓦卢",
                    "name": "Tuvalu",
                    "alpha3": "TUV",
                    "code": "798"
                },
                {
                    "alpha2": "UG",
                    "nameCN": "乌干达",
                    "name": "Uganda",
                    "alpha3": "UGA",
                    "code": "800"
                },
                {
                    "alpha2": "UA",
                    "nameCN": "乌克兰",
                    "name": "Ukraine",
                    "alpha3": "UKR",
                    "code": "804"
                },
                {
                    "alpha2": "AE",
                    "nameCN": "阿联酋",
                    "name": "United Arab Emirates",
                    "alpha3": "ARE",
                    "code": "784"
                },
                {
                    "alpha2": "GB",
                    "nameCN": "英国",
                    "name": "United Kingdom",
                    "alpha3": "GBR",
                    "code": "826"
                },
                {
                    "alpha2": "US",
                    "nameCN": "美国",
                    "name": "United States",
                    "alpha3": "USA",
                    "code": "840"
                },
                {
                    "alpha2": "UM",
                    "nameCN": "美国本土外小岛屿",
                    "name": "United States MI",
                    "alpha3": "UMI",
                    "code": "581"
                },
                {
                    "alpha2": "UY",
                    "nameCN": "乌拉圭",
                    "name": "Uruguay",
                    "alpha3": "URY",
                    "code": "858"
                },
                {
                    "alpha2": "UZ",
                    "nameCN": "乌兹别克斯坦",
                    "name": "Uzbekistan",
                    "alpha3": "UZB",
                    "code": "860"
                },
                {
                    "alpha2": "VU",
                    "nameCN": "瓦努阿图",
                    "name": "Vanuatu",
                    "alpha3": "VUT",
                    "code": "548"
                },
                {
                    "alpha2": "VE",
                    "nameCN": "委内瑞拉",
                    "name": "Venezuela",
                    "alpha3": "VEN",
                    "code": "862"
                },
                {
                    "alpha2": "VN",
                    "nameCN": "越南",
                    "name": "Viet Nam",
                    "alpha3": "VNM",
                    "code": "704"
                },
                {
                    "alpha2": "VG",
                    "nameCN": "英属维尔京群岛",
                    "name": "Virgin Islands (British)",
                    "alpha3": "VGB",
                    "code": "92"
                },
                {
                    "alpha2": "VI",
                    "nameCN": "美属维尔京群岛",
                    "name": "Virgin Islands (U.S.)",
                    "alpha3": "VIR",
                    "code": "850"
                },
                {
                    "alpha2": "WF",
                    "nameCN": "瓦利斯和富图纳",
                    "name": "Wallis and Futuna",
                    "alpha3": "WLF",
                    "code": "876"
                },
                {
                    "alpha2": "EH",
                    "nameCN": "西撒哈拉",
                    "name": "Western Sahara",
                    "alpha3": "ESH",
                    "code": "732"
                },
                {
                    "alpha2": "YE",
                    "nameCN": "也门",
                    "name": "Yemen",
                    "alpha3": "YEM",
                    "code": "887"
                },
                {
                    "alpha2": "ZM",
                    "nameCN": "赞比亚",
                    "name": "Zambia",
                    "alpha3": "ZMB",
                    "code": "894"
                },
                {
                    "alpha2": "ZW",
                    "nameCN": "津巴布韦",
                    "name": "Zimbabwe",
                    "alpha3": "ZWE",
                    "code": "716"
                }
        ],
};

export default RegionUtils;