<template>
    <v-container>
        <v-dialog
            v-model="userDetailDialog"
            
        >
            <v-card>
                <v-card-title class="headline">用户详情</v-card-title>
                <v-card-text>
                    <v-container>
                        <!-- <v-row>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                    v-model="userDetail.uid"
                                    label="uid"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                    v-model="userDetail.unionId"
                                    label="unionId"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                    v-model="userDetail.CurrentCoin"
                                    label="Coin"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                    v-model="userDetail.CurrentCash"
                                    label="Cash"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                    v-model="userDetail.modifytime"
                                    label="modifytime"
                                    readonly
                                ></v-text-field>
                            </v-col>
                        </v-row> -->
                        <v-row>
                            <v-textarea
                                :value="JSON.stringify(userDetail)"
                            >
                                
                            </v-textarea>           
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="userDetailDialog = false">
                        关闭
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
                <v-col cols="4">
                    <v-text-field prepend-inner-icon="mdi-account-search" dense v-model="_searchUid" outlined @keydown.enter.prevent="searchUid"></v-text-field>
                </v-col>
        </v-row>
        <v-card>

            <v-data-table
                :headers="headers"
                :items = "items"

                :options.sync="options"
                :server-items-length="serverLength"
                :loading="loading"
                loading-text="加载中..."
                :search="search"
                class="elevation-2"
                @click:row="rowClick"
            >
            <template v-slot:item.modifytime="{item}">
                <v-chip>{{getTimeStrFromTimestamp(item.modifytime)}}</v-chip>
            </template>
            <template v-slot:item.CurrentCoin="{item}">
                <v-chip>{{(item.CurrentCoin)?item.CurrentCoin.toLocaleString():"null"}}</v-chip>
            </template>
            <template v-slot:item.CurrentCash="{item}">
                <v-chip>{{(item.CurrentCash)?item.CurrentCash.toLocaleString():"null"}}</v-chip>
            </template>

            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "PoolClashUserProfile",
        components: {},
        data: () => ({
            headers: [
            {
                    text: "uid",
                    align: "center",
                    sortable: false,
                    value: "uid",
                    width: "5%",

                },
                 {
                    text: "unionId",
                    align: "center",
                    sortable: false,
                    value: "unionId",
                    width: "5%",

                },
                {
                   text: "Coin",
                   align: "center",
                   value: "CurrentCoin", 
                   width: "5%",
                },
                {
                   text: "Cash",
                   align: "center",
                   value: "CurrentCash", 
                   width: "5%",
                },
                {
                    text: "modifytime",
                    align: "center",
                    value: "modifytime",
                    width: "10%",
                },
            ],
            items: [],
            options: {},
            serverLength: 0,
            loading: false,
            search: null,
            _searchUid: null,
            userDetail: null,
            userDetailDialog: false,
            page: 0,
            step: 20,
        }),
        watch: {
            options: {
                handler() {
                    this.reloadData();
                },
                deep: true,
            },
        },
        mounted: function() {
            this.reloadData();
        },
        methods: {
            getUserDetailFromServer(uid){
                var vm = this;
                axios.get('/admin/api/poolclash/getUserProfile?uid='+uid)
                .then(function(response){
                    vm.userDetail = response.data.data;
                    vm.userDetailDialog = true;
                });
            },
            searchUid: function(){
                this.showUserDetail(this._searchUid);
            },

            showUserDetail(uid) {
                if(!uid){
                    return;
                }
                this.getUserDetailFromServer(uid)
            },
            rowClick: function(item) {
                console.log(item.uid);
                this.showUserDetail(item.uid);
            },
            getTimeStrFromTimestamp(timestamp){
               var s = new Date(timestamp * 1000)
               return s.toLocaleString("zh-CN")
            },
            reloadData() {
                const { sortBy, sortDesc,  page, itemsPerPage } = this.options
                var vm = this;
                vm.loading = true;
                var params = new URLSearchParams({
                    page: page-1,
                    step: itemsPerPage,
                    sort_by: sortBy,
                    sort_desc: sortDesc,
                    // staff_creative: vm.staff_creative,
                    // staff_op: vm.staff_op,
                    // ad_platform: vm.selectedAdPlatforms.join(","),
                    // start_date: vm.dateRange[0].format('YYYYMMDD'),
                    // end_date: vm.dateRange[1].format('YYYYMMDD'),
                    // spend_gt: vm.spendGt,
                    // apps: vm.selectedApps.join(","),
                    // countries: vm.selectedCountries.join(","),
                });
                var queryStr = params.toString();
                axios.get('/admin/api/poolclash/listUserProfile?'+queryStr)
                .then(function(response){
                    vm.items = response.data.data.list;
                    vm.loading=false;
                    vm.serverLength = response.data.data.count;
                    
                });
                
            },
        },
    }
</script>