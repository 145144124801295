<template>
    <v-container>
        <v-dialog v-model="editDialog" max-width="600px" persistent>
         <v-card>
             <v-card-title>{{dialogTitle}}应用</v-card-title>
             <v-card-text>
                 <v-row>
                     <v-col cols="12">
                         <v-text-field v-model="itemEdit.appName" label="应用名称"></v-text-field>
                     </v-col>
                    
                 </v-row>
                 <v-row>
                      <v-col cols="5">
                         <v-radio-group row v-model="itemEdit.platform">
                             <v-radio
                                label="安卓"
                                value="android"
                             ></v-radio>
                             <v-radio
                                label="iOS"
                                value="ios"
                             ></v-radio>
                         </v-radio-group>
                     </v-col>
                     <v-col cols="7">
                         <v-text-field v-model="itemEdit.packageName" label="包名"></v-text-field>
                     </v-col>
                 </v-row>
                 <v-row>
                     <v-col cols="12">
                         <v-text-field v-model="itemEdit.storeUrl" label="应用市场链接"></v-text-field>
                     </v-col>
                 </v-row>
                 <v-row>
                     <v-col cols="5">
                         <v-text-field v-model="itemEdit.minVersion" label="最低版本" hint="低于(不包含)此版本的用户将收到更新通知"></v-text-field>
                     </v-col>
                    <v-col cols="4">
                         <v-checkbox label="强制更新(不更新就不给用了)" v-model="itemEdit.forceUpdate" hint="不更新就不给用了"></v-checkbox>
                     </v-col>
                 </v-row>
                 <v-row>
                     <v-col cols="5">
                         <v-text-field v-model="itemEdit.currVersion" label="当前版本" hint="(可选)当前线上最新版本"></v-text-field>
                     </v-col>
                 </v-row>
                     
             </v-card-text>
             <v-card-actions>
                 <v-spacer></v-spacer>
                 <v-btn color="blue darken-1" @click="DailogCancel" text>取消</v-btn>
                 <v-btn color="blue darken-1" @click="DailogSave" text>确定</v-btn>
             </v-card-actions>
         </v-card>

     </v-dialog>
        <v-card loading="uploading">
            <v-toolbar flat>
                <v-toolbar-title>应用配置</v-toolbar-title>
                <v-divider vertical class="mx-2"></v-divider>
                <v-spacer></v-spacer>
                <v-btn outlined color="grey darken-2" @click="showEditDialog()">新增</v-btn>
            </v-toolbar>
             <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="options"
                :server-items-length="serverLength"
                :loading="loading"
                loading-text="加载中..."
                :search="search"
                class="elevation-2"
            >
                <template v-slot:item.modifyTime="{item}">
                    <v-chip>{{getTimeStrFromTimestamp(item.modifyTime)}}</v-chip>
                </template>
                <template v-slot:item.actions="{item}">
                    <v-icon
                        small
                        class="mr-2"
                        @click="showEditDialog(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios'
import RegionUtils from '@/utils/regionUtils'
import {bus} from '@/main'
export default {
    name : "AppMeta",
    components: {
        
    },
    data: function(){
        return {
            headers: [
                {
                    text: "应用名称",
                    align: "center",
                    sortable: false,
                    value: "appName",
                    width: "10%",
                },
                {
                    text: "平台",
                    align: "center",
                    sortable: false,
                    value: "platform",
                    width: "10%",
                },
                {
                    text: "包名",
                    align: "center",
                    sortable: false,
                    value: "packageName",
                    width: "10%",
                },
                {
                    text: "强更版本",
                    align: "center",
                    sortable: false,
                    value: "minVersion",
                    width: "10%",
                },
                {
                    text: "强制更新",
                    align: "center",
                    sortable: false,
                    value: "forceUpdate",
                    width: "10%",
                },
                {
                    text: "当前版本",
                    align: "center",
                    sortable: false,
                    value: "currVersion",
                    width: "10%",
                },
                {
                    text: "修改日期",
                    align: "center",
                    sortable: false,
                    value: "modifyTime",
                    width: "10%",
                },
                {
                    text: "操作",
                    align: "center",
                    sortable: false,
                    value: "actions",
                    width: "10%",

                },
            ],
            loading:false,
            tabs: null,
            tabdata: null,
            editDialog: false,
            uploading:false,
            editLang: '',
            startMenu:false,
            dialogDelete: false,
            search: "",
            options:{},
            items:[],
            endMenu:false,
            serverLength:0,
            editLocalName: '',
            isModifying: false,
            langCandiate: RegionUtils.lang,
            regionCandiate: RegionUtils.regions,
            itemEdit: {
                appName: '',
                packageName: '',
                minVersion: '',
                currVersion:'',
                forceUpdate: false,
                platform: '',
            },

        }
    },
    methods:{
         getTimeStrFromTimestamp(timestamp){
            var s = new Date(timestamp)
            return s.toLocaleString("zh-CN")
        },
        reloadData: function(){
            console.log("will reload data");
            var vm = this;
            const { page, itemsPerPage } = this.options
            axios.get("/admin/api/appmeta/listApp?page="+(page-1)+'&step='+itemsPerPage).then(function(response){
                if(response.data.status == 0){
                    console.log(response);
                    vm.items = response.data.data.list;
                    vm.loading=false;
                    vm.serverLength = response.data.data.totalCnt;
                }else{
                    bus.$emit("showSnackMessage", "刷新event数据失败" + response.data.msg, true);
                }
            }).catch(function(err){
                bus.$emit("showSnackMessage", "刷新collection数据失败，出现异常 ：" +err);
            })
        },
        clearItemEdit: function(){
            this.itemEdit =  {
                appName: '',
                packageName: '',
                minVersion: '',
                currVersion:'',
                forceUpdate: false,
                platform: '',
            };
        },
        deleteItem: function(item){
            console.log("delete item clicked");
            this.itemEdit = Object.assign({}, item);
            this.dialogDelete = true;
            var vm = this;
            this.$confirm("确定要删除?").then(res => {
                if(res === true){
                    var form = new FormData();
                    form.append("id", this.itemEdit.id);
                    axios.post("/admin/api/appmeta/delApp", form, {
                        headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).then(function(response){
                        if(response.data.status == 0){
                             bus.$emit("删除成功");
                             vm.clearItemEdit();
                             vm.isModifying = false;
                             vm.reloadData();
                        }else{
                            bus.$emit("showSnackMessage", "删除错误: " + response.data.msg, true);                      
                        }
                    }).catch(function(err){
                        bus.$emit("showSnackMessage", "错误发生: " + err, true);
                    })
                }

            })
        },

        showEditDialog: function(modify){
            if (modify){
                this.itemEdit = Object.assign({}, modify);
            }else{
                this.clearItemEdit();
            }
            this.editDialog = true;
        },

        addApp: function(){
            this.uploading = true;
            var vm = this;
            //eventItem.levelCnt = eventItem.levels.length;
            var formData = new FormData();
            for ( var key in this.itemEdit ) {
                var item = this.itemEdit[key];
                // if (key == "packages"){
                //     formData.append(key, JSON.stringify(item));
                //     continue;
                // }

                
                if (Array.isArray(item)){
                    for(var index in item){
                        formData.append(key, item[index])
                    }
                }else if (typeof item === "object" && item != null){
                    formData.append(key, JSON.stringify(item))
                }else{
                    formData.append(key, item)
                }
                
            }
            axios.post('/admin/api/appmeta/addApp', formData, {
                headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(response){              
                console.log(response);
                vm.clearItemEdit();
                if(response.data.status == 0){
                    vm.isModifying = false;
                    vm.editDialog = false;
                    vm.uploading = false;
                    bus.$emit("showSnackMessage", "新增成功")
                    vm.reloadData();
                }else{
                    bus.$emit("showSnackMessage", "新增/修改失败 " + response.data.status + " " + response.data.msg, true);
                    vm.uploading = false;
                }
                
            }).catch(function(error){
                console.log(error);
                vm.uploading = false;
                bus.$emit("showSnackMessage", "新增失败" + error, true)
                vm.reloadData();
            });
        },
        
        DailogSave: function(){
            this.addApp();
        },
        DailogCancel: function(){
            this.editDialog = false;
            this.clearItemEdit();
        },
    },
    computed: {
        //  isFreeLable:function(){
        //     return this.itemEdit.isFree ? "免费": "收费";
        // },
        dialogTitle: function(){
            return this.isModifying ? "修改":"新建";
        },
        // dialogTitle: function(){
        //     return this.isModifying?"修改Picture":"新建Picture";
        // },
    },
    watch: {
        options:{
            handler(){
                this.reloadData();
            },
            deep: true,
        },

    },
    mounted: function(){
        this.reloadData();
    },
}
</script>