var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-dialog',{attrs:{"width":"800px"},model:{value:(_vm.magnifyImgDialog),callback:function ($$v) {_vm.magnifyImgDialog=$$v},expression:"magnifyImgDialog"}},[_c('v-card',[_c('v-img',{attrs:{"src":_vm.magnifyImgUrl}})],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.serverLength,"loading":_vm.loading,"loading-text":"加载中...","search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.isFree",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getIsFreeColor(item.isFree)}},[_vm._v(_vm._s(_vm.getIsFreeContent(item.isFree)))])]}},{key:"item.collections",fn:function(ref){
var item = ref.item;
return _vm._l((item.collections),function(collection,index){return _c('v-chip',{key:index},[_vm._v(_vm._s(collection))])})}},{key:"item.modifyTime",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(_vm.getTimeStrFromTimestamp(item.modifyTime)))])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"rounded-lg",attrs:{"max-width":"60","max-height":"38","src":item.url+'?x-oss-process=style/w_200'},on:{"click":function($event){return _vm.magnifyImage(item)}}})]}},{key:"item.regions",fn:function(ref){
var item = ref.item;
return _vm._l((item.regions),function(region,index){return _c('v-chip',{key:index},[_vm._v(_vm._s(_vm.getCNNameByCode(region)))])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.makefirst(item)}}},[_vm._v(" mdi-arrow-up-bold ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Mystery")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":"","inset":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[(_vm.editedItem.url != '')?_c('v-img',{staticClass:"mb-1",attrs:{"height":"200","src":_vm.editedItem.url}}):_vm._e(),_c('v-card-title',{staticClass:"text-h5"},[_vm._v("确认要删除?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("取消")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("确认")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" 新建 ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.editedItem.url != '')?_c('v-img',{staticClass:"mb-1",attrs:{"height":"200","src":_vm.editedItem.url}}):_vm._e(),(_vm.editedItem.url == '')?_c('v-card-title',[_vm._v(_vm._s(_vm.dialogTitle))]):_vm._e(),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"label":"上传图片","filled":"","accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-camera"},on:{"change":_vm.fileuploadChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Url","rules":_vm.urlRules,"disabled":""},model:{value:(_vm.editedItem.url),callback:function ($$v) {_vm.$set(_vm.editedItem, "url", $$v)},expression:"editedItem.url"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Hash","disabled":""},model:{value:(_vm.editedItem.hash),callback:function ($$v) {_vm.$set(_vm.editedItem, "hash", $$v)},expression:"editedItem.hash"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"可见地区","items":_vm.regionCandiate,"item-text":"nameCN","item-value":"alpha2","multiple":"","chips":""},model:{value:(_vm.editedItem.regions),callback:function ($$v) {_vm.$set(_vm.editedItem, "regions", $$v)},expression:"editedItem.regions"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancelPicture}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.savePicture}},[_vm._v(" 保存 ")])],1)],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }