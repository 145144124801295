<template>
    <v-container>
        <v-card class="ma-3">
            <v-tabs v-model="tab" grow>
                <v-tab>章节配置</v-tab>
            </v-tabs>
            <v-card class="ma-3">
                <v-tabs-items v-model="tab" class="mt-3" flat>
                    <v-tab-item>
                        <dj-chapter-config/>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-card>
    </v-container>
</template>
<script>
import DiceJigsawChapterConfig from '@/components/dicejigsaw/ChapterConfig.vue'
export default {
    components: {
        'dj-chapter-config': DiceJigsawChapterConfig,
    },
    name: "JigsawDice",
    data: ()=>{
        return {
            tab: null,
        }
    }
}
</script>
