<template>
    <v-container>
        <v-dialog width="800px" v-model="magnifyImgDialog">
            <v-card>
                <v-img :src="magnifyImgUrl"></v-img>
            </v-card>

        </v-dialog>

       <v-data-table
        :headers = "headers"
        :items = "items"
        :options.sync="options"
        :server-items-length="serverLength"
        :loading="loading"
        loading-text="加载中..."
        :search="search"
        class="elevation-2"
       >       
       <template v-slot:item.isFree="{ item }">
           <v-chip
            :color="getIsFreeColor(item.isFree)"
           >{{getIsFreeContent(item.isFree)}}</v-chip>
       </template>
       <template v-slot:item.collections="{ item }">
           <v-chip v-for="collection, index in item.collections" :key="index">{{collection}}</v-chip>
       </template>
       <template v-slot:item.modifyTime="{ item }">
           <v-chip>{{getTimeStrFromTimestamp(item.modifyTime)}}</v-chip>
       </template>
       <template v-slot:item.url="{ item }">
           <v-img @click="magnifyImage(item)" class="rounded-lg" max-width="60" max-height="38" :src="item.url+'?x-oss-process=style/w_200'"/>
           <!-- <v-icon small absolute color="primary">mdi-magnify-plus</v-icon> -->
       </template>
       <template v-slot:item.regions="{ item }">
            <v-chip v-for="region, index in item.regions" :key="index">{{getCNNameByCode(region)}}</v-chip>
       </template>
       <template v-slot:item.actions="{ item }">
           <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
           >
                mdi-pencil
           </v-icon>
           <v-icon
                small
                @click="deleteItem(item)"
           >
                mdi-delete
           </v-icon>
           <v-icon
                small
                @click="makefirst(item)"
           >
                mdi-arrow-up-bold
           </v-icon>
       </template>
       
        

        <template v-slot:top>
           <v-toolbar flat>
               <v-toolbar-title>Mystery</v-toolbar-title>
               <v-divider vertical inset class="mx-4"></v-divider>
               <v-spacer></v-spacer>
               <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                     <v-img class="mb-1" v-if="editedItem.url != ''"
                            height="200"
                            :src="editedItem.url"
                        >

                    </v-img>
                    <v-card-title class="text-h5">确认要删除?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">取消</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">确认</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>
               <v-dialog 
                    v-model="dialog" 
                    persistent
                    max-width="500px"
               >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        color="primary"
                        dark
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        >
                            新建
                        </v-btn>
                    </template>
                    <v-card>
                        <v-img class="mb-1" v-if="editedItem.url != ''"
                            height="200"
                            :src="editedItem.url"
                        >

                        </v-img>

                        <v-card-title v-if="editedItem.url == ''">{{ dialogTitle }}</v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-file-input
                                            label="上传图片"
                                            filled
                                            accept="image/png, image/jpeg, image/bmp"
                                            prepend-icon="mdi-camera"
                                            @change="fileuploadChange"
                                        >
                                            <template v-slot:selection="{ text }">
                                                <v-chip
                                                    small
                                                    label
                                                    color="primary"
                                                >{{ text }}</v-chip>
                                            </template>
                                        </v-file-input>
                                    </v-col>
    
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Url"
                                            v-model="editedItem.url"
                                            :rules="urlRules"
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Hash"
                                            v-model="editedItem.hash"
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="6">
                                        <v-checkbox
                                            v-model="editedItem.isFree"
                                            :label="isFreeLable"
                                            @change="isFreeChange"
                                        >
                                        </v-checkbox>
                                    </v-col> -->
                                    <!-- <v-col cols="6">
                                        <v-select
                                            v-model="editedItem.price"
                                            :disabled="editedItem.isFree"
                                            label="价格"
                                            :items = "priceCandiate"
                                            item-text = "price"
                                            item-value = "price"
                                        >
                                        </v-select>
                                        
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select
                                            v-model="editedItem.collections"
                                            :items="collectionCandiate"
                                            label="图集"
                                            item-text="name"
                                            item-value="name"
                                            multiple
                                            chips
                                        ></v-select>
                                    </v-col> -->
                                    <v-col cols="12">
                                        <v-autocomplete
                                            v-model="editedItem.regions"
                                            label="可见地区"
                                            :items="regionCandiate"
                                            item-text="nameCN"
                                            item-value="alpha2"
                                            multiple
                                            chips
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="cancelPicture"
                            >
                                取消
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="savePicture"
                            >
                                保存
                            </v-btn>
                        </v-card-actions>
                    </v-card>
           </v-dialog>             
           </v-toolbar>
           
       </template>
      

       </v-data-table>
    </v-container>
</template>


<script>
import axios from 'axios'
import MiscUtils from '@/utils/miscUtils'
import RegionUtils from '@/utils/regionUtils'
import {bus} from '@/main'

export default {
    name: 'RecMysteryConfig',

    data: function(){
        return {
            headers: [
                {
                    text: "Id",
                    align: "center",
                    sortable: false,
                    value: "id",
                    width: "10%",

                },
                {
                   text: "Hash",
                   align: "center",
                   value: "hash", 
                   width: "10%",
                },
                {
                    text: "预览",
                    align: "center",
                    value: "url",
                    width: "10%",
                },
                {
                    text: "地域",
                    align: "center",
                    value: "regions",
                    width: "20%",
                },
                // {
                //     text: "图集",
                //     align: "center",
                //     value: "collections",
                //     width: "20%",
                // },
                // {
                //     text: "费用",
                //     align: "center",
                //     value: "isFree",
                //     width: "5%",
                // },
                // {
                //     text: "价格",
                //     align: "center",
                //     value: "price",
                //     width: "5%",
                    
                // },
                {
                    text: "修改时间",
                    align: "center",
                    value: "modifyTime",
                    width: "10%",
                },
                {
                    text: "操作",
                    align: "center",
                    value: "actions",
                    width: "10%",
                }
            ],
            items: [],
            search: "",
            loading: true,
            dialog: false,
            options:{},
            serverLength:0,
            editedItem: {
                url: '',
                hash: '',
                isFree: true,
                price: 0,
                collections: [],
                regions:['AA'],
            },
            priceCandiate: [
                {tier: 1, price: 0.99, currency: "USD"},
                {tier: 2, price: 1.99, currency: "USD"},
                {tier: 3, price: 2.99, currency: "USD"},
                {tier: 4, price: 3.99, currency: "USD"},
                {tier: 5, price: 4.99, currency: "USD"},
            ],
            urlRules: MiscUtils.rules.urlRules,
            regionCandiate: RegionUtils.regions,
            isModifying: false,
            dialogDelete: false,
            showNoData: false,
            magnifyImgUrl: '',
            magnifyImgDialog: false,
            collectionCandiate:[
                // {name:"Scene"},
                // {name:"Carton"},
                // {name:"Masterpiece"},
                // {name: "Plants"},
                // {name: "Animal"},
                // {name: "Pets"},

            ]
        }
    },

    watch: {
        options:{
            handler(){
                this.reloadData();
            },
            deep: true,
        },

    },

    computed: {
        isFreeLable:function(){
            return this.editedItem.isFree ? "免费": "收费";
        },
        dialogTitle: function(){
            return this.isModifying?"修改Mystery":"新建Mystery";
        },
    },

    methods: {
        makefirst: function(item){
            var vm = this;
            var formData = new FormData()
            formData.append("id", item.id)
            axios.post("/admin/api/jigsaw/makefirstRecMystery", formData)
                .then(function(response){
                    console.log(response);
                    if(response.data.status == 0){
                        bus.$emit("showSnackMessage", "置顶成功");
                    }else{
                        bus.$emit("showSnackMessage", "置顶失败" + response.data.msg);
                    }
                    vm.reloadData();
                })
        },
        deleteItemConfirm:function(){
            var vm = this;
            var formData = new FormData();
            formData.append("id", this.editedItem.id);
            axios.post('/admin/api/jigsaw/delRecMystery', formData, {
                headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(response){              
                console.log(response);
                vm.clearEditedItem();
                vm.isModifying = false;
                vm.dialogDelete = false;
                vm.reloadData();
                bus.$emit("showSnackMessage", "删除成功")
            }).catch(function(error){
                console.log(error);
                bus.$emit("showSnackMessage", "删除失败 " + error, true)
                vm.reloadData();
            });
        },
        magnifyImage: function(item){
            this.magnifyImgUrl = item.url;
            this.magnifyImgDialog = true;
        },
        getCNNameByCode: RegionUtils.getCNNameByCode.bind(RegionUtils),
        closeDelete: function(){
            this.dialogDelete = false;
        },
        getTimeStrFromTimestamp(timestamp){
            var s = new Date(timestamp)
            return s.toLocaleString("zh-CN")
        },
        pagination: function(page){
            console.log("pagination param is :" + page);
        },
        getIsFreeColor: function(isFree){
            return isFree?"green":"orange";
        },
        getIsFreeContent: function(isFree){
            return isFree?"免费":"付费";
        },
        fileuploadChange: function(files){
            console.log(files)
            var formData = new FormData();
            formData.append("image", files);
            var vm = this;
            axios.post("/admin/api/uploadFile", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.status == 0){
                    vm.editedItem.url = response.data.data.url;
                    vm.editedItem.hash = response.data.data.hash;
                }else{
                    bus.$emit("showSnackMessage", "图片上传失败 " + response.data.msg, true)
                }
              
            }).catch(function(){
                bus.$emit("showSnackMessage", "图片上传失败 ", true)
                console.log("upload failed");

            })
        },

        clearEditedItem: function(){
            this.editedItem =  {
                url: '',
                hash: '',
                isFree: true,
                price: 0,
                collections: [],
                regions:['AA'],
            };
        },

        editItem: function(item){
            var index = this.items.indexOf(item);
            if (index !== -1){
                this.isModifying = true;
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            }else{
                this.isModifying = false;
            }
           
        },

        deleteItem: function(item){
            console.log("delete item clicked");
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        addPicture: function(){
            var vm = this;
            var formData = new FormData();
            for ( var key in this.editedItem ) {
                var item = this.editedItem[key];
                if (Array.isArray(item)){
                    for(var index in item){
                        formData.append(key, item[index])
                    }
                }else{
                    formData.append(key, item)
                }
                
            }
            axios.post('/admin/api/jigsaw/addRecMystery', formData, {
                headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(response){              
                console.log(response);
                vm.clearEditedItem();
                vm.isModifying = false;
                vm.dialog = false;
                bus.$emit("showSnackMessage", "新增成功")
                vm.reloadData();
            }).catch(function(error){
                console.log(error);
                bus.$emit("showSnackMessage", "新增失败" + error, true)
                vm.reloadData();
            });
        },

        modPicture: function(){
            var vm = this;
            var formData = new FormData();
             for ( var key in this.editedItem ) {
                var item = this.editedItem[key];
                if (Array.isArray(item)){
                    for(var index in item){
                        formData.append(key, item[index])
                    }
                }else{
                    formData.append(key, item)
                }
                
            }
            axios.post('/admin/api/jigsaw/addRecMystery', formData, {
                headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(response){              
                console.log(response);
                vm.clearEditedItem();
                vm.isModifying = false;
                vm.dialog = false;
                bus.$emit("showSnackMessage", "修改成功")
                vm.reloadData();
            }).catch(function(error){
                console.log(error);
                bus.$emit("showSnackMessage", "修改失败 "+error, true);
                vm.reloadData();
            });
        },

        savePicture: function(){
           if(this.isModifying){
               this.modPicture();
           }else{
               this.addPicture();
           }
           
        },
        cancelPicture: function(){
            this.clearEditedItem();
            this.isModifying = false;
            this.dialog = false;
        },

        isFreeChange: function(){
            if(this.editedItem.isFree){
                this.editedItem.price = 0;
            }
        },
        
        reloadData: function(){
            const { page, itemsPerPage } = this.options
            console.log(page)
            console.log(itemsPerPage)
            var vm = this;
            axios.get('/admin/api/jigsaw/listRecMystery?page='+(page-1)+'&step='+itemsPerPage)
                .then(function(response){
                    vm.items = response.data.data.list;
                    vm.loading=false;
                    vm.serverLength = response.data.data.totalCnt;
                    
            });
            axios.get("/admin/api/listCollection")
                .then(function(response){
                    if(response.data.status == 0){
                        vm.collectionCandiate = response.data.data;
                    }
            });
        },

          
     
    },
    mounted: function(){
       this.reloadData();
    }
}
</script>

<style>
.test{
    background-color: blue;
}
</style>
